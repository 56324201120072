// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const OrderStatus = {
  "COMPLETED": "COMPLETED",
  "DRAFT": "DRAFT",
  "READY_FOR_PICKUP": "READY_FOR_PICKUP",
  "PENDING_VENDOR": "PENDING_VENDOR",
  "REJECTED": "REJECTED",
  "CANCELLED": "CANCELLED",
  "IN_PROGRESS": "IN_PROGRESS",
  "IN_TRANSIT": "IN_TRANSIT",
  "INCOMPLETE": "INCOMPLETE"
};

const TimeSlotType = {
  "DINE_IN": "DINE_IN",
  "PICK_UP": "PICK_UP",
  "DELIVERY": "DELIVERY"
};

const FoodType = {
  "DRINK": "DRINK",
  "SNACK": "SNACK",
  "ENTREE": "ENTREE",
  "VEGAN": "VEGAN"
};

const RecurrenceFrequency = {
  "DAILY": "DAILY",
  "WEEKLY": "WEEKLY",
  "MONTHLY": "MONTHLY",
  "YEARLY": "YEARLY"
};

const Weekday = {
  "MON": "MON",
  "TUE": "TUE",
  "WED": "WED",
  "THU": "THU",
  "FRI": "FRI",
  "SAT": "SAT",
  "SUN": "SUN"
};

const EventExceptionType = {
  "CANCELLED": "CANCELLED",
  "UPDATED": "UPDATED"
};

const VendorStatus = {
  "ACTIVE": "ACTIVE",
  "INACTIVE": "INACTIVE",
  "DEACTIVATED": "DEACTIVATED"
};

const EventType = {
  "BUFFET": "BUFFET",
  "A_LA_CART": "A_LA_CART"
};

const VenueCategory = {
  "STADIUM": "STADIUM",
  "DINING_HALL": "DINING_HALL"
};

const TimeSlotsStatus = {
  "ERROR": "ERROR",
  "PROVISIONING": "PROVISIONING",
  "READY": "READY",
  "NEED_UPDATE": "NEED_UPDATE"
};

const AccountStatus = {
  "ACTIVE": "ACTIVE",
  "PENDING": "PENDING",
  "REJECTED": "REJECTED",
  "INACTIVE": "INACTIVE"
};

const BusinessStatus = {
  "ACTIVE": "ACTIVE",
  "INACTIVE": "INACTIVE",
  "DEACTIVATED": "DEACTIVATED"
};

const ReservationStatus = {
  "PLACED": "PLACED",
  "COMPLETED": "COMPLETED",
  "REJECTED": "REJECTED",
  "CANCELLED": "CANCELLED",
  "EXPIRED": "EXPIRED"
};

const BookingStatus = {
  "PLACED": "PLACED",
  "COMPLETED": "COMPLETED",
  "ACCEPTED": "ACCEPTED",
  "REJECTED": "REJECTED",
  "CANCELLED": "CANCELLED",
  "EXPIRED": "EXPIRED",
  "PAYMENT_ERROR": "PAYMENT_ERROR",
  "NO_SHOW": "NO_SHOW"
};

const { Order, Customer, Cart, OrderItem, Food, MenuFood, Menu, EventMenu, Event, TimeSlot, EventException, Venue, Vendor, University, Business, Provider, Report, OrderState, Reservation, Booking, Service, Deliverer, Director, Staff, Availability, AvailabilityException, Category, S3Object } = initSchema(schema);

export {
  Order,
  Customer,
  Cart,
  OrderItem,
  Food,
  MenuFood,
  Menu,
  EventMenu,
  Event,
  TimeSlot,
  EventException,
  Venue,
  Vendor,
  University,
  Business,
  Provider,
  Report,
  OrderState,
  Reservation,
  Booking,
  Service,
  Deliverer,
  Director,
  Staff,
  Availability,
  AvailabilityException,
  Category,
  OrderStatus,
  TimeSlotType,
  FoodType,
  RecurrenceFrequency,
  Weekday,
  EventExceptionType,
  VendorStatus,
  EventType,
  VenueCategory,
  TimeSlotsStatus,
  AccountStatus,
  BusinessStatus,
  ReservationStatus,
  BookingStatus,
  S3Object
};