import React, { useState } from 'react';
import { Button, Form, Input } from 'antd';
import Header from './Header';
import { updateProvider } from '@campus/shared/services/UserService';

const ProviderName = ({ provider, onNext, onError }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onFinish = async values => {
    console.log('input data:', values);
    // validate input

    // update customer w/name
    setLoading(true);
    const providerData = {
      firstName: values.first,
      lastName: values.last
    };
    const updateResults = await updateProvider(providerData, provider.id);
    setLoading(true);
    // handle error
    if (updateResults.error) return onError('Issue updating provider');
    onNext();
  };

  return (
    <div className="p-4 w-full md:w-1/2">
      <Header step={1} label={'What is your name?'} />
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        size="large"
        requiredMark={false}
      >
        <Form.Item
          label="First Name"
          name="first"
          autofocus
          rules={[{ required: true, message: 'Please enter your first name' }]}
          style={{ marginBottom: 8 }}
        >
          <Input placeholder="" />
        </Form.Item>
        <Form.Item
          label="Last Name"
          name="last"
          rules={[{ required: true, message: 'Please enter your last name' }]}
        >
          <Input placeholder="" className="w-20" />
        </Form.Item>
        <div className="mt-16">
          <Button
            name="submit"
            type="primary"
            size="large"
            className="w-[100px]"
            loading={loading}
            htmlType="submit"
          >
            Next
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default ProviderName;
