import { API, graphqlOperation, Storage } from "aws-amplify";
import { AmpAPI, configureAmplify } from "./AmplifyService";
import { MUTATIONS, QUERIES, AWS_CONFIG } from "@campus/backend";
import { GraphQLResult } from "@aws-amplify/api-graphql";
import { CreateMenuMutation, CreateVenueInput, GetVenueQuery, GetVenueQueryVariables, ReporstByVenueQuery } from "@campus/backend/API";

const {
  aws_user_files_s3_bucket_region: region,
  aws_user_files_s3_bucket: bucket
} = AWS_CONFIG;
configureAmplify();

const addVenueToUniversity = async (props) => {
  try {
    const { data } = (await API.graphql(
      graphqlOperation(MUTATIONS.createVenue, {
        input: { ...props }
      })
    )) as GraphQLResult<CreateVenueInput>;

    const { id: venueID } = data['createVenue'];

    AmpAPI.post('x-VenueAPI', '/venue/generate-timeslots', {
      body: { venueID }
    }).then(() => { });

    return data['createVenue'];
  } catch (e) {
    console.error(e);
  }
};

const updateVenue = async params => {
  try {
    const { data } = (await API.graphql(
      graphqlOperation(MUTATIONS.updateVenue, {
        input: {
          ...params
        }
      })
    )) as GraphQLResult<CreateVenueInput>;

    const { id: venueID } = data['updateVenue'];

    AmpAPI.post('x-VenueAPI', '/venue/generate-timeslots', {
      body: { venueID }
    }).then(() => { });

    return data['updateVenue'];
  } catch (e) {
    console.error(e);
  }
};

const uploadImage = async (file, uid) => {
  const { type } = file;

  try {
    // get the file extension. eg. jpeg
    const fileExt = type.split('/').pop();
    // create a unique name for the uploaded file + extension
    const key = `venueImages/${uid}-venue-picture.${fileExt}`;
    // prepared gql mutation parameter
    const fileForUpload = {
      bucket,
      key,
      region
    };

    // uploads the image
    await Storage.put(key, file, {
      contentType: type
    });
    console.log("uploaded image, ", key)
    // Prepare data to be updated
    const inputData = { image: fileForUpload };

    return inputData;
  } catch (e) {
    console.error(e);
  }
}


const getVenueImage = async food => {
  try {
    if (food.image) {
      return await Storage.get(food.image.key);
    } else if (food.image_url) {
      return food.image_url;
    } else {
      return null //"https://source.unsplash.com/collection/28445433"; //Placeholder image if there is nothing saved yet
    }
  } catch (e) {
    console.log(e);
  }
}


const getVenue = async venueID => {
  try {
    console.log("getVenue: ", venueID);
    const { data } = (await API.graphql(
      graphqlOperation(QUERIES.getVenue, { id: venueID })
    )) as GraphQLResult<GetVenueQuery>;

    return data;
  } catch (e) {
    console.error(e);
  }
};

const getReportsByVenue = async venueID => {
  try {
    const { data } = (await API.graphql(
      graphqlOperation(QUERIES.reporstByVenue, { venueID })
    )) as GraphQLResult<ReporstByVenueQuery>;

    return data["reporstByVenue"];
  } catch (e) {
    console.error(e);
  }
}




export { addVenueToUniversity, updateVenue, getVenue, getReportsByVenue, uploadImage, getVenueImage };
