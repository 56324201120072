import React, { useState, useEffect } from 'react';
import { Button, Modal, Calendar, Select, Radio, Col, Row, Layout } from 'antd';
import {
  getAvailability,
  isValidDate
} from '../../../../shared/services/AvailabilityService';
import moment from 'moment';
import { useBusinessStore } from '../../stores/business';

export default function AvailabilityCalendar({
  availabilities,
  onEditAvailability
}) {
  const [businessState] = useBusinessStore();
  const business = businessState.business || null;

  useEffect(() => {
    business && getAvailability(business.id);
  }, []);

  const dateCellRender = date => {
    const dayAvailabilities = availabilities.filter(event => {
      return isValidDate(event, date);
    });

    return (
      <div>
        {dayAvailabilities.map(availability => {
          const localTime = moment.utc(availability.startTime, 'HH:mm').local();
          const isDisabled = availability?.exceptions?.items
            .map(v => v.date)
            .includes(date.format('YYYY-MM-DD'));
          const formatAMPM = time =>
            time.includes('am') ? time.split('m')[0] : time.split('m')[0];
          return (
            <div
              className={`hover:bg-gray-100 text-xs hover:shadow-sm py-1 rounded ${
                isDisabled ? ' text-red-400' : ''
              }`}
              key={availability.id}
              onClick={() => {
                onEditAvailability(availability, date);
              }}
            >
              {formatAMPM(localTime.format('h:mma')) +
                '-' +
                formatAMPM(
                  localTime
                    .add(availability.duration, 'minutes')
                    .format('h:mma')
                )}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <Calendar
      headerRender={({ value, type, onChange, onTypeChange }) => {
        const start = 0;
        const end = 12;
        const monthOptions = [];
        if (!value) value = moment();
        const current = value.clone();
        const localeData = value.localeData();
        const months = [];
        for (let i = 0; i < 12; i++) {
          current.month(i);
          months.push(localeData.monthsShort(current));
        }
        for (let index = start; index < end; index++) {
          monthOptions.push(
            <Select.Option className="month-item" key={`${index}`}>
              {months[index]}
            </Select.Option>
          );
        }
        const month = value.month();

        const year = value.year();
        const options = [];
        for (let i = moment().year(); i < year + 10; i += 1) {
          options.push(
            <Select.Option key={i} value={i} className="year-item">
              {i}
            </Select.Option>
          );
        }
        return (
          <div className="pb-3 flex justify-center">
            <Row gutter={8}>
              <Col>
                <Radio.Group
                  size="medium"
                  onChange={e => onTypeChange(e.target.value)}
                  value={type}
                >
                  <Radio.Button value="month">Month</Radio.Button>
                  <Radio.Button value="year">Year</Radio.Button>
                </Radio.Group>
              </Col>
              <Col>
                <Select
                  size="medium"
                  dropdownMatchSelectWidth={false}
                  className="my-year-select"
                  onChange={newYear => {
                    const now = value.clone().year(newYear);
                    onChange(now);
                  }}
                  value={String(year)}
                >
                  {options}
                </Select>
              </Col>
              <Col>
                <Select
                  size="medium"
                  dropdownMatchSelectWidth={false}
                  value={String(month)}
                  onChange={selectedMonth => {
                    const newValue = value.clone();
                    newValue.month(parseInt(selectedMonth, 10));
                    onChange(newValue);
                  }}
                >
                  {monthOptions}
                </Select>
              </Col>
            </Row>
          </div>
        );
      }}
      dateCellRender={dateCellRender}
    />
  );
}
