import React, { useEffect } from 'react';
import { useNavigate } from '@reach/router';
import { AppUser } from '../Auth';
import { SplitLayout } from '../Layout';
import loginBack from '../../assets/images/admin_image.jpeg';

export const LoginBack = () => {
  return (
    <div className="w-full h-full">
      <img className="w-full h-full object-cover" src={loginBack} />
    </div>
  );
};

const PublicRoute = ({ component: Component, location, ...rest }) => {
  const navigate = useNavigate();

  return (
    <SplitLayout rightComponent={<LoginBack />}>
      <Component {...rest} />
    </SplitLayout>
  );
};

export default PublicRoute;
