import React, { useState, useEffect } from 'react';
import { navigate, useParams } from '@reach/router';
import { Button, Form, Checkbox, Input } from 'antd';
import { useToasts } from 'react-toast-notifications';
import ProviderName from './ProviderName';
import { SplitLayout } from '../../../Layout';
import { LoginBack } from '../../../Routes/PublicRoute';
import ProviderPhone from './ProviderPhone';
import BusinessName from './Business';
import Business from './Business';
import Location from './Location';
import Stripe from './Stripe';
import {
  getProvider,
  getProviderByID
} from '@campus/shared/services/UserService';
import { Auth } from 'aws-amplify';
import { setUser } from '../../../Auth/AppUser';

const MainScreen = props => {
  const { addToast } = useToasts();
  const params = useParams();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState('name');
  const [provider, setProvider] = useState(null);

  useEffect(() => {
    checkPageParam(window.location.hash);
    getProviderInfo();
  }, []);

  const checkPageParam = page => {
    if (page) {
      setPage(page.split('#')[1]);
    }
  };

  const getProviderInfo = async () => {
    const myProvider = await getProvider();
    if (!myProvider) {
      // automatically redirect if provider does not exist
      setUser({});
      await Auth.signOut();
      navigate('/app/provider');
    }
    setProvider(myProvider);
  };

  const nextPage = () => {
    switch (page) {
      case 'name':
        addToast('Name saved successfully', {
          appearance: 'success'
        });
        if (!provider.phoneNumber) setPage('phone');
        else navigate('/app/provider');
        return;
      case 'phone':
        addToast('Phone saved successfully', {
          appearance: 'success'
        });
        if (provider.businesses.items.length === 0) setPage('business');
        else navigate('/app/provider');
        return;
      case 'business':
        addToast('Business created successfully', {
          appearance: 'success'
        });
        setPage('location');
        return;
      case 'location':
        addToast('Location saved successfully', {
          appearance: 'success'
        });
        setPage('stripe');
        return;
      default:
        alert('no page handle');
    }
  };

  const showError = errMessage => {
    addToast(errMessage, {
      appearance: 'error'
    });
  };

  return (
    <SplitLayout rightComponent={<LoginBack />} showLogout>
      <div className="flex pt-24 flex-col items-center justify-center">
        {!provider ? (
          <div>Loading...</div>
        ) : page === 'name' ? (
          <ProviderName
            provider={provider}
            onNext={nextPage}
            onError={showError}
          />
        ) : page === 'phone' ? (
          <ProviderPhone
            provider={provider}
            onNext={nextPage}
            onError={showError}
          />
        ) : page === 'business' ? (
          <Business provider={provider} onNext={nextPage} onError={showError} />
        ) : page === 'location' ? (
          <Location onNext={nextPage} />
        ) : page === 'stripe' ? (
          <Stripe />
        ) : null}
      </div>
    </SplitLayout>
  );
};

export { MainScreen };
