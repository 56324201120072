import React, { useState } from 'react';
import { navigate, useLocation } from '@reach/router';
import { parse } from 'query-string';
import { Auth } from 'aws-amplify';
import { Button } from 'antd';
import {
  getDirector,
  updateUserAttributes
} from '@campus/shared/services/UserService';
import {
  getUniversity,
  updateUniversity
} from '@campus/shared/services/UniversityService';
import { AppUser } from '../../Auth';
import { AmpAPI } from '@campus/shared/services/AmplifyService';
import { useUniversityStore } from '../../../stores/university';
import { SplitLayout } from '../../Layout';
import { LoginBack } from '../../Routes/PublicRoute';

const StripeFlowComplete = props => {
  const [loading, setLoading] = useState(true);
  const [universityState] = useUniversityStore();
  const location = useLocation();
  const { code } = parse(location.search);
  const { setUser } = AppUser;

  React.useEffect(() => {
    if (!code) {
      alert('Error occured, stripe set up incomplete. Please try again.');
      return;
    }
    AmpAPI.post('x-PaymentsAPI', '/payments/connected-account', {
      body: { authorizationCode: code }
    })
      .then(data => {
        console.log(data);
        updateUserAttributes({
          'custom:stripe_connected_id': data.stripe_user_id
        }).then(res => {
          console.log(res);
          completeStripe();
        });
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  const completeStripe = async () => {
    const user = await Auth.currentAuthenticatedUser();
    const userGroups =
      user.signInUserSession.accessToken.payload['cognito:groups'];

    if (userGroups.includes('providers')) {
      // handle any owner actions after stripe set up
      // navigate to create venue flow
      navigate('/app/provider');
    } else {
      console.log(
        'User does not have permission to set up Stripe, navigating to safety.'
      );
      navigate('/');
      return;
    }

    // Update local user with new attributes
    const userInfo = {
      ...user.attributes,
      username: user.username,
      userGroups
    };
    setUser(userInfo);
    setLoading(false);
  };

  return (
    <SplitLayout rightComponent={<LoginBack />}>
      <div className="h-full flex flex-col items-center justify-center">
        <div className="w-1/2 flex flex-col space-y-6">
          {loading ? (
            <div className="h-full flex flex-col items-center">
              <div className="text-xl py-4">
                Please wait, finishing stripe set up...
              </div>
            </div>
          ) : (
            <div className="h-full flex flex-col items-center">
              <div className="text-xl py-4">
                You are all done. You may continue to the app.
              </div>
              <Button
                className="w-full"
                onClick={() => navigate('/')}
                type="primary"
              >
                Continue
              </Button>
            </div>
          )}
        </div>
      </div>
    </SplitLayout>
  );
};

export default StripeFlowComplete;
