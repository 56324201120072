import React, { useState, useEffect } from 'react';
import { Button, Form, Input, Select } from 'antd';
import Header from './Header';
import { createBusiness } from '@campus/shared/services/BusinessService';
import { getBusinessCategories } from '@campus/shared/services/BusinessService';
const { Option } = Select;

const Business = ({ provider, onNext, onError }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [categoryList, setCategoryList] = useState(null);

  useEffect(() => {
    getCategories();
  }, []);

  const getCategories = async () => {
    const categories = await getBusinessCategories();
    setCategoryList(categories.listCategorys.items);
  };

  const renderCategories = categoryList
    ? Object.values(categoryList)
        .map(category => {
          return (
            <Option
              key={category.id}
              value={category.id}
              category={category.title}
            >
              {category.title}
            </Option>
          );
        })
        .sort((a, b) => a.props.category.localeCompare(b.props.category))
    : null;

  const onFinish = async values => {
    console.log('input data:', values);
    // validate input

    // create customer w/name
    setLoading(true);
    const businessData = {
      providerID: provider.id,
      universityID: provider.universityID,
      name: values.name,
      categories: values.categories ? values.categories : [],
      description: values.description || null,
      links: values.link ? [values.link] : []
    };
    const createResults = await createBusiness(businessData);
    setLoading(true);
    // handle error
    if (createResults.error) return onError('Failed to create business');
    onNext();
  };

  return (
    <div className="p-4 w-full md:w-1/2">
      <Header step={3} label={'Lets set up your business'} />
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        size="large"
        requiredMark={false}
      >
        {/* NAME */}
        <Form.Item
          label="Business Name"
          name="name"
          autofocus
          style={{ marginBottom: 8 }}
          rules={[
            { required: true, message: 'Please enter your business name' }
          ]}
        >
          <Input placeholder="" />
        </Form.Item>
        {/* CATEGORY */}
        <Form.Item
          label="Categories"
          name="categories"
          rules={[
            {
              required: true,
              message:
                'Help people find your business easier by adding a category.'
            }
          ]}
        >
          <Select
            mode="multiple"
            allowClear
            placeholder="Choose categories for your business"
          >
            {renderCategories}
          </Select>
        </Form.Item>
        {/* DESCRIPTION */}
        <Form.Item
          label="Description"
          name="description"
          style={{ marginBottom: 8 }}
          rules={[
            {
              required: true,
              message: 'Please enter your business description'
            }
          ]}
        >
          <Input.TextArea />
        </Form.Item>
        {/* LINKS */}
        <Form.Item
          label="Social Profile Url"
          name="link"
          style={{ marginBottom: 8 }}
          rules={[{ required: false }]}
        >
          <Input placeholder="ex. https://www.instagram.com/petermckinnon" />
        </Form.Item>
        <div className="mt-16">
          <Button
            name="submit"
            type="primary"
            size="large"
            className="w-[100px]"
            loading={loading}
            htmlType="submit"
          >
            Next
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default Business;
