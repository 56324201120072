import { Button, Card, Image } from 'antd';
import { EditOutlined, PhoneOutlined, UploadOutlined } from '@ant-design/icons';
import Icon from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import {
  setProvider,
  useBusinessDispatch,
  useBussinessState
} from '../../../contexts/BusinessContext';
import {
  getProfilePicture,
  getProvider,
  getProviderByID,
  updateProvider
} from '@campus/shared/services/UserService';
import { useAppStore } from '../../../stores/app';
import { getCurrentUser } from '../../Auth/AppUser';
import { UpdateProviderImage } from '../../Forms/UpdateProviderImage';
import BasicModal from '../../Dialogs/BasicModal';
import { UpdateProviderForm } from '../../Forms/UpdateProviderForm';
import { useToasts } from 'react-toast-notifications';
import { navigate } from 'gatsby';

function ProfileCard() {
  const dispatch = useBusinessDispatch();
  const { business, provider, providerLoading } = useBussinessState();
  const [providerImage, setProviderImage] = useState(null);
  const [appState] = useAppStore();
  const [showModal, setShowModal] = useState('');
  const [modalLoading, setModalLoading] = useState(false);
  const { addToast } = useToasts();

  useEffect(() => {
    //load provider
    business && loadProvider();
  }, [business]);

  const loadProvider = async () => {
    // Admins get provider from businessID
    const myProvider = getCurrentUser().userGroups.includes('admins')
      ? await getProviderByID(business.providerID)
      : await getProvider();
    console.log('Load Provider', myProvider);
    // Check Provider Data

    if (myProvider) {
      // Fetch profile image
      await getProfilePicture(myProvider).then(src => {
        setProviderImage(src);
      });
      setProvider(dispatch, myProvider);
    }
  };

  const onUpdateProvider = async values => {
    console.log({ values });
    setModalLoading(true);
    const result = await updateProvider(values, provider.id);

    if (result.error) {
      addToast(result.error.message, {
        appearance: 'error'
      });
    }

    setShowModal('');
    setModalLoading(false);
    loadProvider();
  };

  const formatPhone = num => {
    let formatted = num.replace(
      /(\d{1,2})(\d{1})?(\d{1,3})?(\d{1,4})?/,
      function(_, p1, p2, p3, p4) {
        let output = '';
        if (p1) output = `(${p1}`;
        if (p2) output += `${p2})`;
        if (p3) output += ` ${p3}`;
        if (p4) output += ` ${p4}`;
        return output;
      }
    );
    return formatted;
  };

  return (
    <>
      <Card
        className="h-full"
        loading={providerLoading}
        title={'My Profile'}
        extra={[
          <Button
            type="link"
            icon={<EditOutlined />}
            key="editProfile"
            onClick={() => setShowModal('updateProvider')}
          >
            Edit
          </Button>,
          <Button
            type="link"
            icon={<UploadOutlined />}
            key="uploadPhoto"
            onClick={() => setShowModal('uploadProviderImage')}
          >
            Photo
          </Button>
        ]}
      >
        <div className="h-full flex items-center">
          {providerImage ? (
            <Image
              className="rounded-full"
              style={{ objectFit: 'cover' }}
              width={80}
              height={80}
              preview={false}
              src={providerImage}
            />
          ) : (
            <div className="w-20 h-20 rounded-full bg-gray-300"></div>
          )}
          <div className="px-6">
            <p className="m-0 p-0 text-lg">
              <span>{provider?.firstName || 'No Name Set'}</span>{' '}
              <span>{provider?.lastName || ''}</span>
            </p>
            <p className="m-0 p-0 text-gray-600">
              {provider?.invitationalEmail || 'Email Not Found'}
            </p>
            {provider?.phoneNumber && (
              <div className="flex items-center space-x-2 mt-1">
                <PhoneOutlined className="text-gray-600" />
                <p className="m-0 p-0 text-gray-600">
                  {formatPhone(provider?.phoneNumber)}
                </p>
              </div>
            )}
          </div>
        </div>
      </Card>
      <BasicModal isVisible={showModal} onCancel={() => setShowModal('')}>
        {showModal === 'uploadProviderImage' ? (
          <UpdateProviderImage
            onCancel={() => setShowModal('')}
            onSubmit={() => {
              setShowModal('');
              loadProvider();
            }}
            isLoading={modalLoading}
            business={business}
          />
        ) : showModal === 'updateProvider' ? (
          <UpdateProviderForm
            onCancel={() => setShowModal('')}
            onSubmit={onUpdateProvider}
            isLoading={modalLoading}
            provider={provider}
          />
        ) : null}
      </BasicModal>
    </>
  );
}

export default ProfileCard;
