import React, { useState } from 'react';
import { Button, Form, Input } from 'antd';
import Header from './Header';
import { updateProvider } from '@campus/shared/services/UserService';

const ProviderPhone = ({ provider, onNext, onError }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onFinish = async values => {
    console.log('input data:', values);
    // validate input
    const cleanPhone = values.phone.match(/\d+/g, '').join('');
    console.log('validation: ', cleanPhone);
    if (cleanPhone.length !== 10) {
      return alert('Not enough digits, must be 10');
    }
    // create customer w/name
    setLoading(true);
    const providerData = {
      phoneNumber: cleanPhone
    };
    const updateResults = await updateProvider(providerData, provider.id);
    setLoading(true);
    // handle error
    if (updateResults.error) return onError('Issue updating provider');
    // handle error
    onNext();
  };

  return (
    <div className="p-4 w-full md:w-1/2">
      <Header step={2} label={'What is your phone number?'} />
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        size="large"
        requiredMark={false}
      >
        <Form.Item
          label="Phone Number"
          name="phone"
          autofocus
          style={{ marginBottom: 8 }}
          rules={[
            {
              required: true,
              message: 'Please enter your phone number'
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                console.log('got value: ', value.match(/\d/g));
                if (value && value.match(/\d/g).length === 10) {
                  return Promise.resolve();
                }

                return Promise.reject(
                  new Error('Phone number must contain 10 digits')
                );
              }
            })
          ]}
        >
          <Input type="tel" maxLength={16} placeholder="(000) 000 - 0000" />
        </Form.Item>
        <div className="mt-16">
          <Button
            name="submit"
            type="primary"
            size="large"
            className="w-[100px]"
            loading={loading}
            htmlType="submit"
          >
            Next
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default ProviderPhone;
