import React, { useEffect, useState } from 'react';
import { Form, Input, Button, message, Upload } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { updateProviderPicture } from '../../../../shared/services/UserService';
import { uploadBusinessImage } from '@campus/shared/services/BusinessService';

// setLoading(true);
// updateProviderPicture(info.file).then(data => {
//   getBase64(info.file, imageUrl => {
//     setImageUrl(imageUrl);
//     setLoading(false);
//   });
// });

export function UpdateBusinessImage({ onCancel, onSubmit, business }) {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {}, []);

  const handleChange = info => {
    console.log(info);
    const isJpgOrPng =
      info.file.type === 'image/jpeg' || info.file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = info.file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }
    if (isJpgOrPng && isLt2M) {
      setLoading(true);
      //TODO: business picture
      uploadBusinessImage(business, info.file).then(data => {
        getBase64(info.file, imageUrl => {
          setImageUrl(imageUrl);
          setLoading(false);
          onSubmit();
        });
      });
    }
  };

  function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
  }

  function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error('Image must smaller than 2MB!');
    }

    return false;
  }
  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  return (
    <div className="flex flex-col pt-8">
      <div className="w-full text-center">
        <h2 className="text-lg">Upload Business Image</h2>
      </div>
      <div className="flex-1  mt-4 ">
        <Form form={form} onFinish={onSubmit}>
          <div className="px-5 flex justify-center">
            <Form.Item name="upload" extra="Max Size 2MB">
              <Upload
                name="profile"
                listType="picture-card"
                className="avatar-uploader"
                showUploadList={false}
                multiple={false}
                beforeUpload={beforeUpload}
                onChange={handleChange}
              >
                {imageUrl ? (
                  <img
                    src={imageUrl}
                    alt="avatar"
                    style={{ width: 100, height: 100, objectFit: 'cover' }}
                  />
                ) : (
                  uploadButton
                )}
              </Upload>
            </Form.Item>
          </div>
        </Form>
      </div>
    </div>
  );
}
