import React, { useEffect, useState } from 'react';
import { Form, Input, Button, InputNumber, Select } from 'antd';
export function AddServiceForm({ onCancel, onSubmit, isLoading }) {
  const [form] = Form.useForm();
  const { Option } = Select;

  useEffect(() => {}, []);

  return (
    <div className="flex flex-col pt-8">
      <div className="w-full text-center">
        <h2 className="text-lg">Create Service</h2>
      </div>
      <div className="flex-1  mt-4">
        <Form
          form={form}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 16 }}
          layout="horizontal"
          title="Add Service"
          onFinish={onSubmit}
        >
          <div className="px-5">
            <Form.Item label="Name" name="name" rules={[{ required: true }]}>
              <Input placeholder="Service Name..." type="text" maxLength={25} />
            </Form.Item>
            <Form.Item label="Description" name="description">
              <Input
                placeholder="Describe your service in more detail"
                type="text"
                maxLength={250}
              />
            </Form.Item>
            <Form.Item label="Price" name="price" rules={[{ required: true }]}>
              <Input
                type="number"
                addonBefore="$"
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                onChange={val => console.log(val.price)}
                min={1}
                max={1000}
              />
            </Form.Item>
            <Form.Item
              label="Duration"
              name="duration"
              initialValue="30"
              rules={[{ required: true }]}
            >
              <Select>
                <Option value="30">30 min</Option>
                <Option value="60">1 Hour</Option>
                <Option value="90">1.5 Hour</Option>
                <Option value="120">2 Hour</Option>
                <Option value="180">3 Hour</Option>
                <Option value="240">4 Hour</Option>
                <Option value="300">5 Hour</Option>
                <Option value="360">6 Hour</Option>
                <Option value="420">7 Hour</Option>
                <Option value="480">8 Hour</Option>
              </Select>
            </Form.Item>
          </div>
          <div className="flex items-center mt-16">
            <Button
              type="ghost"
              onClick={onCancel}
              className="flex-1"
              style={{
                borderRadius: 0,
                height: 50,
                border: 0,
                backgroundColor: '#efefef',
                borderBottomLeftRadius: 2,
                borderBottomRightRadius: 0
              }}
            >
              CANCEL
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              className="flex-1"
              loading={isLoading}
              style={{
                borderRadius: 0,
                height: 50,
                border: 0,
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 2
              }}
            >
              CREATE
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
}
