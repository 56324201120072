import { API, graphqlOperation } from 'aws-amplify';
import { configureAmplify } from './AmplifyService';
import { MUTATIONS, QUERIES } from '../../backend';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import {
  CreateUniversityInput,
  GetUniversityQuery,
  ListUniversitysQuery,
  UpdateUniversityMutation
} from '../../backend/API';

configureAmplify();

const getUniversities = async () => {
  try {
    const { data } = (await API.graphql(
      graphqlOperation(QUERIES.listUniversitys)
    )) as GraphQLResult<ListUniversitysQuery>;
    return data.listUniversitys.items;
  } catch (e) {
    console.error(e);
  }
};

const getUniversity = async universityID => {
  try {
    const { data } = (await API.graphql(
      graphqlOperation(QUERIES.getUniversity, { id: universityID })
    )) as GraphQLResult<GetUniversityQuery>;
    return data.getUniversity;
  } catch (e) {
    console.error(e);
  }
};

const addUniversity = async params => {
  try {
    const { data } = (await API.graphql(
      graphqlOperation(MUTATIONS.createUniversity, {
        input: params
      })
    )) as GraphQLResult<CreateUniversityInput>;
    return data['createUniversity'];
  } catch (e) {
    console.error(e);
  }
};

const updateUniversity = async params => {
  try {
    const { data } = (await API.graphql(
      graphqlOperation(MUTATIONS.updateUniversity, {
        input: {
          ...params
        }
      })
    )) as GraphQLResult<UpdateUniversityMutation>;
    return data.updateUniversity;
  } catch (e) {
    console.error(e);
  }
};

export { getUniversities, addUniversity, updateUniversity, getUniversity };
