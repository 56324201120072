import React from 'react';

const Header = ({ step, label }) => {
  return (
    <div className="mb-14">
      <div className="text-sm text-gray-500">Step {step} of 5</div>
      <div className="text-2xl">{label}</div>
    </div>
  );
};

export default Header;
