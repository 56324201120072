import React, { useState, useEffect } from 'react';
import { Typography, DatePicker, Table, Layout } from 'antd';
import moment from 'moment';
import { Button } from 'antd';
import 'isomorphic-fetch';
import {
  getDateString,
  getLocalTimeslot
} from '@campus/shared/services/SharedService';
import { useVenueStore } from '../../../stores/venue';
import {
  getBookingByBusiness,
  getBookingByBusinessAndDate,
  onCreateBookingByBusinessID$,
  onUpdateBookingByBusinessID$
} from '../../../../../shared/services/BusinessService';
import { useBusinessStore } from '../../../stores/business';
import BookingDetails from './BookingDetails';
import { useBussinessState } from '../../../contexts/BusinessContext';

const STATUS_COLOR = Object.freeze({
  PENDING_VENDOR: 'text-yellow-500',
  IN_PROGRESS: 'text-blue-500',
  COMPLETED: 'text-green-500',
  CANCELLED: 'text-red-500'
});

const Bookings = ({ businessID }) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showDetails, setShowDetails] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [incomingBooking, setIncomingBooking] = useState(null);
  const [updatingBookings, setUpdatingBookings] = useState([]);
  const [venueState] = useVenueStore();
  const { business, businessLoading } = useBussinessState();

  const [rawBookings, setRawBookings] = useState([]);
  // Filtered data (consumable)
  const [filtered, setFiltered] = useState([]);

  useEffect(() => {
    let updateBookingSub, createBookingSub;
    if (business) {
      // subscribe for live updates
      // createBookingSub = onCreateBookingByBusinessID$(business.id).subscribe({
      //   next: ({ value }) => {
      //     const { data } = value;
      //     console.log('sub Data', data);
      //     const newBooking = data.onCreateBookingByBusiness;
      //     if (!newBooking) return;
      //     console.log('Sub got new booking update:', newBooking);
      //     setIncomingBooking(newBooking);
      //   }
      // });
      updateBookingSub = onUpdateBookingByBusinessID$(business.id).subscribe({
        next: ({ value }) => {
          const { data } = value;
          console.log('sub Data', data);
          const newBooking = data.onUpdateBookingByBusiness;
          if (!newBooking) return;
          console.log('Sub got new booking update:', newBooking);
          setIncomingBooking(newBooking);
        }
      });
    }
    return () => {
      // createBookingSub.unsubscribe();
      updateBookingSub.unsubscribe();
    };
  }, []);

  useEffect(() => {
    if (incomingBooking) {
      if (selectedRow && incomingBooking.id === selectedRow.id) {
        setSelectedRow(incomingBooking);
      }
      // check if correct date
      if (selectedDate && !isIncomingValidDate(incomingBooking)) return;
      // update list with new order
      injectIntoRawOrders(incomingBooking);
    }
  }, [incomingBooking]);

  useEffect(() => {
    // console.log(business.id);
    getBookingsByDate();
  }, [selectedDate]);

  useEffect(() => {
    console.log({ rawBookings });
    if (rawBookings) setFiltered(rawBookings);
  }, [rawBookings]);

  const isIncomingValidDate = booking => {
    // get createdAt date
    const date = moment(booking.date, 'YYYY-MM-DD');
    // check if it is during the selected day
    return date.isSame(selectedDate, 'day');
  };

  const injectIntoRawOrders = updatedBooking => {
    let temp = [];
    // check if updatedBooking is an existing order
    if (rawBookings.map(i => i.id).includes(updatedBooking.id)) {
      temp = rawBookings.map(item =>
        item.id === updatedBooking.id ? updatedBooking : item
      );
    } else {
      // if new order
      temp = [...rawBookings, updatedBooking];
    }
    // set orders with new/updated order
    setRawBookings(temp);
  };

  const getBookingsByDate = async () => {
    setLoading(true);
    if (selectedDate) {
      let startDate = selectedDate
        .clone()
        .hour(0)
        .minute(0)
        .format('YYYY-MM-DD');
      let endDate = selectedDate
        .clone()
        .hour(0)
        .minute(0)
        .add(1, 'days')
        .format('YYYY-MM-DD');
      const data = await getBookingByBusinessAndDate(
        business.id,
        startDate,
        endDate
      );

      // set Raw order data
      setRawBookings(data);
      setLoading(false);
    } else {
      const data = await getBookingByBusiness(business.id);
      console.log('all bookings: ', data);
      // set Raw order data
      setRawBookings(data);
      setLoading(false);
    }
  };

  const filterData = () => {
    // sort by newest
    const filtered_data = [...rawBookings].sort((a, b) => {
      const dateA = selectedDate ? moment(a.time, 'HH:mm') : moment(b.date);
      const dateB = selectedDate ? moment(b.time, 'HH:mm') : moment(a.date);
      if (dateA > dateB) {
        return 1;
      }
      if (dateA < dateB) {
        return -1;
      }
      return 0;
    });
    // set filtered orders
    setFiltered(filtered_data);
  };

  const onRefreshOrders = () => {
    getBookingsByDate();
  };

  function onDateChange(date, dateString) {
    if (dateString) {
      const local = moment(dateString);
      console.log('selectedDate: ', local, dateString);
      setSelectedDate(local);
    } else {
      setSelectedDate(null);
    }
  }

  // Table Column Item Render Specs
  const columns = [
    {
      title: 'Booking ID',
      dataIndex: 'id',
      key: '1',
      width: 150,
      render: (value, row, index) => {
        const obj = {
          children: '#' + value.split('-')[0].toUpperCase(),
          props: {}
        };
        return obj;
      }
    },
    {
      title: 'Appointment Date',
      dataIndex: 'date',
      key: '2',
      width: 150,
      defaultSortOrder: 'descend',
      sorter: {
        compare: (a, b) => {
          const dateA = moment(b.date);
          const dateB = moment(a.date);
          if (dateA > dateB) {
            return 1;
          }
          if (dateA < dateB) {
            return -1;
          }
          return 0;
        },
        multiple: 2
      },
      render: (value, row, index) => {
        const obj = {
          children: moment(value, 'YYYY-MM-DD').format('ddd MM/DD/YYYY'),
          props: {}
        };
        return obj;
      }
    },
    {
      title: 'Time',
      dataIndex: 'time',
      key: '3',
      width: 150,
      defaultSortOrder: 'ascend',
      sorter: {
        compare: (a, b) => {
          const dateA = moment(a.time, 'HH:mm');
          const dateB = moment(b.time, 'HH:mm');
          if (dateA > dateB) {
            return 1;
          }
          if (dateA < dateB) {
            return -1;
          }
          return 0;
        },
        multiple: 1
      },
      render: (value, row, index) => {
        const obj = {
          children: moment
            .utc(value, 'HH:mm')
            .local()
            .format('hh:mm a'),
          props: {}
        };
        return obj;
      }
    },
    {
      title: 'Customer',
      dataIndex: 'customer',
      key: '4',
      width: 150,
      render: (value, row, index) => {
        const obj = {
          children: value?.firstName + ' ' + value?.lastName,
          props: {}
        };
        return obj;
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      width: 170,
      key: '5',
      render: (value, row, index) => {
        return (
          <Typography.Text className="flex-1">
            <span className={STATUS_COLOR[value] + ' h-2 text-lg leading-3'}>
              &#8226;{' '}
            </span>
            {value ? (value == 'EXPIRED' ? 'ACCEPTED' : value) : 'No Status'}
          </Typography.Text>
        );
      }
    }
  ];

  return (
    <Layout style={{ background: '#FFFFFF', paddingBottom: '1em' }}>
      <div className="flex items-center">
        <Button className="ml-3 my-3" onClick={onRefreshOrders}>
          Refresh
        </Button>
        <Button className="ml-3 my-3" onClick={() => setSelectedDate(null)}>
          Show All
        </Button>
        <div className="ml-3 my-3">
          <DatePicker
            onChange={onDateChange}
            placeholder="Select a date"
            size="default"
            value={selectedDate}
            defaultValue={moment()}
            showToday
          />
        </div>
      </div>
      <div className="mx-3  overflow-y-auto flex-1">
        <Table
          loading={loading}
          columns={columns}
          onRow={(record, rowIndex) => {
            return {
              onClick: event => {
                console.log(record);
                setSelectedRow(record);
                setShowDetails(true);
              }
            };
          }}
          pagination={false}
          rowKey={order => {
            return order.id;
          }}
          dataSource={filtered}
          scroll={{ x: 'calc(75vw - 4em' }}
          sticky
          tableLayout="auto"
        />
      </div>
      <BookingDetails
        onClose={() => setShowDetails(false)}
        isVisible={showDetails}
        booking={selectedRow}
      />
    </Layout>
  );
};

export default Bookings;
