import React, { useState, useEffect } from 'react';
import { navigate, useParams } from '@reach/router';
import { Auth } from 'aws-amplify';

import { AppUser } from '../../Auth';

import { Button, Form, Checkbox, Input } from 'antd';
import { useToasts } from 'react-toast-notifications';

import { useAppStore } from '../../../stores/app';
import { SplitLayout } from '../../Layout';
import { LoginBack } from '../../Routes/PublicRoute';

const SignIn = () => {
  const { addToast } = useToasts();
  const { setUser, isLoggedIn } = AppUser;
  const [state, actions] = useAppStore();
  const [form] = Form.useForm();
  const [isLoading, setLoading] = useState(false);
  const params = useParams();

  useEffect(() => {
    form.setFieldsValue({
      username: params.username,
      password: params.password
    });
    return () => {};
  }, [params]);

  const login = async values => {
    if (!values.terms) {
      addToast('Please accepts terms and conditions.', { appearance: 'error' });
      return;
    }
    try {
      setLoading(true);
      const userSnapshot = await Auth.signIn(values.username, values.password);
      if (userSnapshot.challengeName === 'NEW_PASSWORD_REQUIRED') {
        actions.setUser(userSnapshot);
        navigate('/app/set-password');
        return;
      }
      const user = await Auth.currentAuthenticatedUser();
      const userGroups =
        user.signInUserSession.accessToken.payload['cognito:groups'];
      const userInfo = {
        ...user.attributes,
        username: user.username,
        userGroups // REMOVE SAVING USERGROUP MAKE DYNAMIC
      };
      setUser(userInfo);
      if (userGroups.includes('admins')) navigate('/app/admin');
      else if (userGroups.includes('providers')) navigate('/app/provider');
      else {
        setUser({});
        await Auth.signOut();
      }
    } catch (err) {
      addToast(err.message || err, { appearance: 'error' });
      console.log('error...: ', err.message || err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="flex h-full flex-col items-center justify-center">
      <div className="w-1/2 flex flex-col space-y-6">
        <Form
          form={form}
          initialValues={{
            terms: false
          }}
          layout="vertical"
          onFinish={login}
          size="large"
          requiredMark={false}
        >
          <Form.Item
            label="Email"
            name="username"
            rules={[
              {
                type: 'email',
                message: 'The input is not valid E-mail!'
              },
              { required: true, message: 'Please enter your Username!' }
            ]}
          >
            <Input name="username" placeholder="example@domain.com" />
          </Form.Item>
          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: 'Please enter your password!' }]}
          >
            <Input.Password name="password" placeholder="Password..." />
          </Form.Item>
          <div className="mb-5">
            <a href="/app/forgotpassword">Forgot Password?</a>
          </div>
          <Form.Item name="terms" valuePropName="checked">
            <Checkbox>
              Accept <a href="/app/termsandconditions">Terms and Conditions</a>{' '}
              and <a href="/app/policy">Privacy Policy</a>
            </Checkbox>
          </Form.Item>
          <div>
            <Button
              name="submit"
              type="primary"
              shape="round"
              size="large"
              className="w-32"
              loading={isLoading}
              htmlType="submit"
            >
              Sign In
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default SignIn;
