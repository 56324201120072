import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Select, Checkbox } from 'antd';

export function UpdateUniversityForm({
  onCancel,
  onSubmit,
  isLoading,
  university
}) {
  const CheckboxGroup = Checkbox.Group;
  const [form] = Form.useForm();
  const { Option } = Select;

  useEffect(() => {
    console.log(university);
    form.setFieldsValue({
      name: university.name,
      domain: university.domain,
      description: university.description,
      utcOffset: university.utcOffset
    });
  }, [university]);

  return (
    <div className="flex flex-col pt-8">
      <div className="w-full text-center">
        <h2 className="text-lg">Update University</h2>
      </div>
      <div className="flex-1  mt-4">
        <Form form={form} layout="vertical" onFinish={onSubmit}>
          <div className="px-5">
            <Form.Item label="Name" name="name" rules={[{ required: true }]}>
              <Input
                placeholder="University of Boston"
                type="text"
                maxLength={50}
              />
            </Form.Item>
            <Form.Item
              label="Domain"
              name="domain"
              rules={[{ required: true }]}
            >
              <Input
                placeholder="@example.edu"
                type="text"
                name="domain"
                maxLength={25}
              />
            </Form.Item>

            <Form.Item
              label="Time Zone"
              name="utcOffset"
              rules={[{ required: true }]}
            >
              <Select>
                <Option value={-7}>Pacific Time Zone (GMT-7)</Option>
                <Option value={-6}>Mountain Time Zone (GMT-6)</Option>
                <Option value={-5}>Central Time Zone (GMT-5)</Option>
                <Option value={-4}>Eastern Time Zone (GMT-4)</Option>
              </Select>
            </Form.Item>

            <Form.Item label="Address" name="description">
              <Input.TextArea
                placeholder="1034 California Ave, Davis, CA"
                name="description"
                maxLength={250}
              />
            </Form.Item>
          </div>

          <div className="flex items-center mt-16">
            <Button
              className="flex-1"
              onClick={onCancel}
              style={{
                borderRadius: 0,
                height: 50,
                backgroundColor: '#efefef',
                border: 0,
                borderBottomLeftRadius: 2,
                borderBottomRightRadius: 0
              }}
            >
              CANCEL
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              className="flex-1"
              loading={isLoading}
              style={{
                borderRadius: 0,
                height: 50,
                border: 0,
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 2
              }}
            >
              UPDATE
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
}
