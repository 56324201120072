import React, { useEffect, useState } from 'react';
import { message } from 'antd';
import Header from './Header';
import Icon from '../../../../assets/vectors/undraw_Credit_card_re_blml.svg';
import { getStripeClientID } from '@campus/shared/services/PaymentService';
import { Auth } from 'aws-amplify';
import { navigate } from 'gatsby';

const Stripe = ({ onNext }) => {
  const [loading, setLoading] = useState(true);
  const clientID = getStripeClientID();
  const stripeUri = `https://connect.stripe.com/express/oauth/authorize?response_type=code&client_id=${clientID}&scope=read_write&redirect_uri`;
  const redirectUri = `${stripeUri}=${window.location.origin}/app/stripe-complete/`;

  useEffect(() => {
    //check if has cognito id
    checkConnected();
  }, []);

  const checkConnected = async () => {
    const user = await Auth.currentAuthenticatedUser();
    if (user.attributes['custom:stripe_connected_id'])
      return navigate('/app/provider');
    else setLoading(false);
  };

  const connectStripe = async () => {
    const hide = message.loading('Redirecting to Stripe...', 0);
    // Dismiss manually and asynchronously
    setTimeout(() => {
      hide();
      window.location.href = redirectUri;
    }, 2500);
  };

  const onFinish = async () => {
    // create customer w/name
    // setLoading(true);
    // handle error
    // setLoading(true);
  };

  return (
    <div className="p-4 w-full md:w-1/2">
      <Header step={5} label={"Let's get paid"} />
      <Icon className="w-[150px] h-[150px] mb-14" />
      <div className="text-gray-600 mb-14 max-w-xs">
        Payments are handled by Stripe. A secure way to get paid.
      </div>
      {loading ? (
        <div>Checking Account...</div>
      ) : (
        <div className="flex">
          <div
            onClick={connectStripe}
            className="text-base rounded px-4 py-2 bg-[#5433FF] shadow-sm text-white cursor-pointer hover:opacity-90"
          >
            Set Up Payments
          </div>
        </div>
      )}
    </div>
  );
};

export default Stripe;
