import { Button, Card, Image } from 'antd';
import { EditOutlined, UploadOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import {
  setBusiness,
  setProvider,
  useBusinessDispatch,
  useBussinessState
} from '../../../contexts/BusinessContext';
import {
  getProfilePicture,
  getProvider,
  getProviderByID,
  updateProvider
} from '@campus/shared/services/UserService';
import { useAppStore } from '../../../stores/app';
import { getCurrentUser } from '../../Auth/AppUser';
import { UpdateProviderImage } from '../../Forms/UpdateProviderImage';
import BasicModal from '../../Dialogs/BasicModal';
import { UpdateBusinessForm } from '../../Forms/UpdateBusinessForm';
import { useToasts } from 'react-toast-notifications';
import { UpdateBusinessImage } from '../../Forms/UpdateBusinessImage';
import {
  getBusiness,
  getBusinessImage,
  updateBusiness
} from '@campus/shared/services/BusinessService';
import { navigate } from 'gatsby';

function BusinessCard({ businessID = undefined }) {
  const dispatch = useBusinessDispatch();
  const { business, provider, businessLoading } = useBussinessState();
  const [businessImage, setBusinessImage] = useState(null);
  const [appState] = useAppStore();
  const [showModal, setShowModal] = useState('');
  const [modalLoading, setModalLoading] = useState(false);
  const { addToast } = useToasts();

  useEffect(() => {
    loadBusiness();
  }, []);

  const loadBusiness = async () => {
    // get business
    if (getCurrentUser().userGroups.includes('admins') && businessID) {
      // get from url query
      const businessData = await getBusiness(businessID);
      setBusiness(dispatch, businessData);
      getBusinessImage(businessData).then(src => {
        setBusinessImage(src);
      });
    } else {
      // get from provider auth
      const provider = await getProvider();
      if (!provider.firstName || !provider.lastName) {
        return navigate('/app/signup#name');
      } else if (!provider.phoneNumber) {
        return navigate('/app/signup#phone');
      }
      const businessData =
        provider?.businesses?.items.length > 0 && provider.businesses.items[0];

      if (!businessData) return navigate('/app/signup#business');
      setBusiness(dispatch, businessData);
      getBusinessImage(businessData).then(src => {
        setBusinessImage(src);
      });
    }
  };

  const loadBusinessImage = () => {};

  const onUpdateBusiness = async businessData => {
    setModalLoading(true);
    try {
      const result = await updateBusiness(businessData);
      if (result.error) throw result.error;
    } catch (e) {
      console.log(e);
      addToast(e.message, {
        appearance: 'error'
      });
    } finally {
      setShowModal('');
      setModalLoading(false);
      loadBusiness();
    }
  };

  return (
    <>
      <Card
        loading={businessLoading}
        title={'My Business'}
        extra={[
          <Button
            type="link"
            icon={<EditOutlined />}
            key="editProfile"
            onClick={() => setShowModal('updateProvider')}
          >
            Edit
          </Button>,
          <Button
            type="link"
            icon={<UploadOutlined />}
            key="uploadPhoto"
            onClick={() => setShowModal('uploadBusinessImage')}
          >
            Photo
          </Button>
        ]}
      >
        <div className="flex flex-col md:flex-row flex-wrap">
          {businessImage ? (
            <img
              className="rounded-md md:mr-8 mb-4 md:mb-0 w-full md:w-32 object-cover bg-gray-300"
              style={{
                height: 128,
                minWidth: 256,
                objectFit: 'cover'
              }}
              src={businessImage}
            />
          ) : (
            <div
              className="rounded-md md:mr-8 h-32 mb-4 w-full md:w-32 object-cover bg-gray-300"
              style={{
                minWidth: 256
              }}
            ></div>
          )}
          <div className="flex flex-col justify-center flex-1 min-w-32">
            <p className="text-lg font-medium mb-0">{business?.name}</p>
            <p className="text-gray-700 mb-2">{business?.description}</p>
            {/* <p className="text-gray-700 mb-0">{business?.telephone}</p> */}
            <p
              className="text-gray-700 mb-0 text-xs"
              style={{
                maxWidth: 160,
                lineBreak: 'anywhere',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxLines: 1
              }}
            >
              {business?.links?.length > 0 ? business.links[0] : ''}
            </p>
          </div>
        </div>
      </Card>
      <BasicModal isVisible={showModal} onCancel={() => setShowModal('')}>
        {showModal === 'uploadBusinessImage' ? (
          <UpdateBusinessImage
            onCancel={() => setShowModal('')}
            onSubmit={() => {
              setShowModal('');
              loadBusiness(); // reloads all university/venues/business
            }}
            isLoading={modalLoading}
            business={business}
          />
        ) : showModal === 'updateProvider' ? (
          <UpdateBusinessForm
            onCancel={() => setShowModal('')}
            onSubmit={onUpdateBusiness}
            isLoading={modalLoading}
            business={business}
          />
        ) : null}
      </BasicModal>
    </>
  );
}

export default BusinessCard;
