import { Button, Card, Popconfirm, List, Image } from 'antd';
import {
  EditOutlined,
  DeleteOutlined,
  UnorderedListOutlined,
  CalendarOutlined,
  PlusOutlined
} from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import {
  useBusinessDispatch,
  useBussinessState
} from '../../../contexts/BusinessContext';
import {
  getProfilePicture,
  getProvider,
  getProviderByID,
  updateProvider
} from '@campus/shared/services/UserService';
import moment from 'moment';
import { useAppStore } from '../../../stores/app';
import { getCurrentUser } from '../../Auth/AppUser';
import { UpdateProviderImage } from '../../Forms/UpdateProviderImage';
import BasicModal from '../../Dialogs/BasicModal';
import { UpdateBusinessForm } from '../../Forms/UpdateBusinessForm';
import { useToasts } from 'react-toast-notifications';
import { UpdateBusinessImage } from '../../Forms/UpdateBusinessImage';
import {
  createService,
  deleteService,
  getBusiness,
  getBusinessImage,
  getServices,
  updateBusiness,
  updateService
} from '@campus/shared/services/BusinessService';
import { AddServiceForm } from '../../Forms/AddServiceForm';
import { UpdateServiceForm } from '../../Forms/UpdateServiceForm';
import { AddAvailabilityForm } from '../../Forms/AddAvailabilityForm';
import { UpdateAvailabilityForm } from '../../Forms/UpdateAvailabilityForm';
import {
  createAvailability,
  deleteAvailability,
  getAvailability,
  updateAvailability
} from '@campus/shared/services/AvailabilityService';
import AvailabilityCalendar from '../../Forms/AvailabilityCalendar';

function AvailabilityCard() {
  const dispatch = useBusinessDispatch();
  const { business, provider, businessLoading } = useBussinessState();
  const [loadingAvailability, setLoadingAvailability] = useState(true);
  const [availabilities, setAvailabilities] = useState([]);
  const [selectedAvailability, setSelectedAvailability] = useState(null);
  const [showCalendar, setShowCalendar] = useState(true);
  const [modalLoading, setModalLoading] = useState(false);
  const [visible, setVisible] = useState({ show: false, id: null });
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [showModal, setShowModal] = useState('');
  const { addToast } = useToasts();

  useEffect(() => {
    console.log('avail busi', business);
    business && loadAvailability();
  }, [business]);

  const loadAvailability = async () => {
    setLoadingAvailability(true);
    const myAvailability = await getAvailability(business.id);
    // update selected availability to update exceptions
    if (selectedAvailability) {
      const index = myAvailability
        .map(val => val.id)
        .findIndex(val => val === selectedAvailability.id);
      // console.log(index);
      if (index >= 0)
        setSelectedAvailability({
          ...myAvailability[index],
          ...(selectedAvailability.dateSelected && {
            dateSelected: selectedAvailability.dateSelected
          })
        });
      else console.log('Selected avail not found...');
    }
    setAvailabilities(myAvailability);
    setLoadingAvailability(false);
  };

  const onCreateAvailability = async values => {
    setModalLoading(true);
    const startTime = values.time[0].date(moment().date()).startOf('minute');
    const endTime = values.time[1].date(moment().date()).startOf('minute');
    //create data
    const data = {
      businessID: business.id,
      name: values.name,
      startDate: values.startDate.format('YYYY-MM-DD'),
      ...(values.endDate && { endDate: values.endDate.format('YYYY-MM-DD') }),
      duration: Math.abs(endTime.startOf('minutes').diff(startTime, 'minutes')), // TODOD FIX ROUNDING ERROR
      startTime: startTime ? startTime.utc().format('HH:mm') : null,
      ...(values.recurrentDays?.length > 0 && {
        recurrentDays: values.recurrentDays
      }),
      active: true
    };
    // make request
    try {
      console.log(data);
      const result = await createAvailability(data);
      if (result) console.log(result);
    } catch (e) {
      setModalLoading(false);
    } finally {
      setModalLoading(false);
      setShowModal('');
      loadAvailability();
    }
  };

  const onEditAvailability = async values => {
    setModalLoading(true);
    const startTime = values.time[0].date(moment().date());
    const endTime = values.time[1].date(moment().date());
    // new data
    const data = {
      id: selectedAvailability.id,
      _version: selectedAvailability._version,
      name: values.name,
      startDate: values.startDate.format('YYYY-MM-DD'),
      ...(values.endDate && { endDate: values.endDate.format('YYYY-MM-DD') }),
      duration: Math.abs(endTime.diff(startTime, 'minutes')),
      startTime: startTime ? startTime.utc().format('HH:mm') : null,
      ...(values.recurrentDays?.length > 0 && {
        recurrentDays: values.recurrentDays
      })
    };
    // make request
    const result = await updateAvailability(data);
    // check if success
    if (result.errors) {
      console.error(result.errors);
      alert('Error Updating Availability');
    }
    // update loading and hide modal
    setModalLoading(false);
    setShowModal('');
    setSelectedAvailability(null);
    loadAvailability();
  };

  const onDeleteAvailability = async availability => {
    setConfirmLoading(true);
    // make request
    const result = await deleteAvailability(availability);
    // check if success
    if (result.errors) console.error(result.errors);
    // update UI
    setConfirmLoading(false);
    setVisible({ show: false, id: null });
    loadAvailability();
  };

  return (
    <>
      <Card
        loading={loadingAvailability}
        title="Availability"
        extra={[
          <Button
            type="link"
            key="toggleView"
            icon={
              showCalendar ? <UnorderedListOutlined /> : <CalendarOutlined />
            }
            onClick={() => setShowCalendar(!showCalendar)}
          >
            {showCalendar ? 'List' : 'Calendar'}
          </Button>,
          <Button
            type="link"
            icon={<PlusOutlined />}
            key="addAvailability"
            onClick={() => setShowModal('addAvailability')}
          >
            {'Availability'}
          </Button>
        ]}
      >
        {showCalendar ? (
          <AvailabilityCalendar
            availabilities={availabilities}
            onEditAvailability={(item, date) => {
              setSelectedAvailability({
                ...item,
                dateSelected: date
              });
              setShowModal('updateAvailability');
            }}
          />
        ) : (
          <List
            loading={loadingAvailability}
            itemLayout="horizontal"
            dataSource={availabilities}
            rowKey={item => item.id}
            renderItem={item => (
              <List.Item
                actions={[
                  <Button
                    type="link"
                    key="edit"
                    icon={<EditOutlined key="edit" />}
                    onClick={() => {
                      setSelectedAvailability(item);
                      setShowModal('updateAvailability');
                    }}
                  >
                    Edit
                  </Button>,
                  <Popconfirm
                    key="delete"
                    title="Confirm Delete Availability"
                    visible={visible.show && visible.id === item.id}
                    placement="left"
                    onConfirm={() => onDeleteAvailability(item)}
                    okButtonProps={{ loading: confirmLoading }}
                    onCancel={() => setVisible({ show: false, id: null })}
                  >
                    <Button
                      type="link"
                      danger
                      key="delete"
                      icon={<DeleteOutlined />}
                      onClick={() => {
                        setVisible({ show: true, id: item.id });
                      }}
                    >
                      Delete
                    </Button>
                  </Popconfirm>
                ]}
              >
                <List.Item.Meta
                  title={item.name}
                  description={
                    item.recurrentDays.map((day, i) => (i ? ' ' + day : day)) +
                    '  | ' +
                    moment
                      .utc(item.startTime, 'HH:mm')
                      .local()
                      .format('hh:mm a') +
                    ' - ' +
                    moment
                      .utc(item.startTime, 'HH:mm')
                      .add(item.duration, 'minutes')
                      .local()
                      .format('hh:mm a')
                  }
                />
              </List.Item>
            )}
          />
        )}
      </Card>
      <BasicModal isVisible={showModal} onCancel={() => setShowModal('')}>
        {showModal === 'addAvailability' ? (
          <AddAvailabilityForm
            onCancel={() => setShowModal('')}
            onSubmit={onCreateAvailability}
            isLoading={modalLoading}
          />
        ) : showModal === 'updateAvailability' ? (
          <UpdateAvailabilityForm
            onCancel={() => setShowModal('')}
            onSubmit={onEditAvailability}
            isLoading={modalLoading}
            editAvail={selectedAvailability}
            onRefresh={() => loadAvailability()}
          />
        ) : null}
      </BasicModal>
    </>
  );
}

export default AvailabilityCard;
