import React from 'react';
import 'antd/dist/antd.less';
import { Router } from '@reach/router';
import {
  SignIn,
  SetPassword,
  StripeFlowStart,
  StripeFlowComplete,
  BusinessSetUp,
  Terms,
  Policy,
  Settings,
  ForgotPassword,
  ResetPassword
} from '../../components/Pages';
import PrivateRoute from '../../components/Routes/PrivateRoute';
import PublicRoute from '../../components/Routes/PublicRoute';
import { ToastProvider } from 'react-toast-notifications';
import { configureAmplify } from '@campus/shared';
import { AdminDash, ProviderDash } from '../../components/Pages/Dashboards';
import { MainScreen } from '../../components/Pages/Auth/ProviderSignUp';
const NotFound = () => (
  <div className="p-4">Sorry, nothing here. Check the URL.</div>
);

const App = () => {
  configureAmplify();
  return (
    <ToastProvider placement="bottom-left" autoDismiss>
      <Router>
        {/* <PrivateRoute path="/" component={Home} /> */}
        <PrivateRoute path="/app/admin" component={AdminDash} />
        <PrivateRoute path="/app/settings" component={Settings} />
        <PrivateRoute path="/app/provider" component={ProviderDash} />
        <PrivateRoute
          path="/app/provider/:businessID"
          component={ProviderDash}
        />
        <PrivateRoute path="/app/stripe-start" component={StripeFlowStart} />
        <PrivateRoute
          path="/app/stripe-complete"
          component={StripeFlowComplete}
        />
        <PrivateRoute path="/app/create-business" component={BusinessSetUp} />
        <PrivateRoute path="/app/signup" component={MainScreen} />
        <PublicRoute path="/app/signin/" component={SignIn} />
        <PublicRoute path="/app/forgotpassword/" component={ForgotPassword} />
        <PublicRoute path="/app/resetpassword/" component={ResetPassword} />
        <PublicRoute path="/app/signin/:username/" component={SignIn} />
        <PublicRoute
          path="/app/signin/:username/:password"
          component={SignIn}
        />
        <PublicRoute path="/app/set-password" component={SetPassword} />
        <PublicRoute path="/app/termsandconditions" component={Terms} />
        <PublicRoute path="/app/policy" component={Policy} />
        <NotFound default />
      </Router>
    </ToastProvider>
  );
};

export default App;
