import React, { useState, useEffect } from 'react';
import { Card, Button, List } from 'antd';
import { LoginOutlined } from '@ant-design/icons';

import { useToasts } from 'react-toast-notifications';
import { navigate } from 'gatsby';

function BusinessCard({ refreshUniversities, selected }) {
  const { addToast } = useToasts();
  const [loading, setLoading] = useState(false);

  return (
    <div className="">
      <Card loading={loading}>
        <List
          loading={loading}
          itemLayout="horizontal"
          dataSource={selected?.businesses?.items || []}
          rowKey={item => item.id}
          renderItem={item => (
            <List.Item
              actions={[
                <Button
                  type="link"
                  key="edit"
                  icon={<LoginOutlined key="edit" />}
                  onClick={() => navigate('/app/provider/' + item.id)}
                >
                  Manage
                </Button>
              ]}
            >
              <List.Item.Meta
                title={item.name}
                description={
                  'Owner ' +
                  item.provider.firstName +
                  ' ' +
                  item.provider.lastName
                }
              />
            </List.Item>
          )}
        />
      </Card>
    </div>
  );
}

export default BusinessCard;
