import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import { Button, Form, Input, Checkbox, Select } from 'antd';
import { useToasts } from 'react-toast-notifications';
import { useAppStore } from '../../../stores/app';
import { SplitLayout, Layout, LayoutScroll } from '../../Layout';
import { LoginBack } from '../../Routes/PublicRoute';
import { createBusiness } from '@campus/shared/services/BusinessService';
import {
  getProviderInfo,
  setProviderInfo
} from '@campus/shared/services/UserManagementService';
import { getCurrentUser } from '../../Auth/AppUser';
import { updateProvider } from '@campus/shared/services/UserService';
import { getBusinessCategories } from '@campus/shared/services/BusinessService';

const BusinessSetUp = () => {
  const { addToast } = useToasts();
  const { Option } = Select;
  const CheckboxGroup = Checkbox.Group;
  const [form] = Form.useForm();
  const [isLoading, setLoading] = useState(false);
  const [state] = useAppStore();
  const [addressVisible, setAddressVisible] = useState(false);
  const [categoryList, setCategoryList] = useState(null);
  const locationOptions = [
    { label: 'At Business', value: 'business' },
    { label: 'House Visit', value: 'house' }
  ];

  useEffect(() => {
    getCategories();
  }, []);

  const getCategories = async () => {
    const categories = await getBusinessCategories();
    setCategoryList(categories.listCategorys.items);
  };

  const renderCategories = categoryList
    ? Object.values(categoryList)
        .map(category => {
          return (
            <Option
              key={category.id}
              value={category.id}
              category={category.title}
            >
              {category.title}
            </Option>
          );
        })
        .sort((a, b) => a.props.category.localeCompare(b.props.category))
    : null;

  const onComplete = async values => {
    setLoading(true);
    // create business
    const currUser = getCurrentUser();
    console.log(currUser);
    const providers = await getProviderInfo({ userID: currUser.username });
    console.log(providers);
    if (providers.length === 0) {
      addToast('Provider not created. Contact Support.', {
        appearance: 'error'
      });
      setLoading(false);
      return;
    }
    const provider = providers[0];
    const businessData = {
      providerID: provider.id,
      universityID: provider.universityID,
      name: values.name,
      locationOptionOnSite: values.location?.includes('business') || false,
      locationOptionVisit: values.location?.includes('house') || false,
      telephone: values.contact || null,
      shortLocation: values.address || null,
      description: values.description || null,
      links: values.link ? [values.link] : [],
      categories: values.categories
    };
    const providerData = {
      firstName: values.firstName,
      lastName: values.lastName
    };
    const providerUpdate = await updateProvider(providerData, provider.id);
    console.log('Create Business:', providerUpdate);
    const businessCreated = await createBusiness(businessData);
    console.log('BusinessCreated:', businessCreated);
    if (businessCreated) {
      navigate('/');
    } else {
      addToast('Provider not created. Contact Support.', {
        appearance: 'error'
      });
      setLoading(false);
    }
  };

  const handleLocationChange = values => {};

  return (
    <SplitLayout rightComponent={<LoginBack />}>
      <div className="flex flex-col items-center justify-center">
        <div className="md:w-1/2 flex flex-col space-y-6">
          <div className="text-xl py-4">Set Up Your Business</div>
          <Form
            form={form}
            layout="vertical"
            onFinish={onComplete}
            onValuesChange={(changed, all) => {
              if (changed.location)
                setAddressVisible(changed.location.includes('business'));
            }}
            size="large"
          >
            <Form.Item
              label="First Name"
              name="firstName"
              style={{ marginBottom: 12 }}
              rules={[{ required: true, message: 'Please input first name.' }]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Last Name"
              name="lastName"
              style={{ marginBottom: 12 }}
              rules={[{ required: true, message: 'Please input last name.' }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Business Name"
              name="name"
              rules={[
                { required: true, message: 'Give your business a name.' }
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Description"
              name="description"
              rules={[
                {
                  required: true,
                  message: 'Share business related info to display at checkout.'
                }
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Categories"
              name="categories"
              rules={[
                {
                  required: true,
                  message:
                    'Help people find your business easier by adding a category.'
                }
              ]}
            >
              <Select
                mode="multiple"
                allowClear
                placeholder="Choose categories for your business"
              >
                {renderCategories}
              </Select>
            </Form.Item>

            <Form.Item
              label="Phone Number"
              name="contact"
              rules={[{ required: true, message: 'Help people contact you.' }]}
            >
              <Input prefix placeholder="+1 123-123-1234" />
            </Form.Item>

            <Form.Item
              label="Place of Business"
              name="location"
              rules={[{ required: true, message: 'Select at least one.' }]}
            >
              <CheckboxGroup options={locationOptions} />
            </Form.Item>
            {addressVisible && (
              <Form.Item
                label="Business Address"
                name="address"
                rules={[{ required: true, message: 'Help people find you.' }]}
              >
                <Input placeholder="123 Street Ave, City ST..." />
              </Form.Item>
            )}

            <Form.Item
              label="Marketing Link (Optional)"
              name="link"
              rules={[{ required: false }]}
            >
              <Input placeholder="Ex. www.instagram.com/jaki_garden" />
            </Form.Item>

            <div>
              <Button
                type="primary"
                size="large"
                className="w-64 mb-8"
                loading={isLoading}
                htmlType="submit"
              >
                Create and Continue
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </SplitLayout>
  );
};

export default BusinessSetUp;
