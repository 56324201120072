import React, { useEffect, useState } from 'react';
import {
  Form,
  Input,
  Button,
  Checkbox,
  Select,
  DatePicker,
  TimePicker,
  Spin
} from 'antd';

export function AddAvailabilityForm({ onCancel, onSubmit, isLoading }) {
  const [form] = Form.useForm();
  const { Option } = Select;
  const { RangePicker } = TimePicker;
  const CheckboxGroup = Checkbox.Group;

  const rangeConfig = {
    rules: [{ type: 'array', required: true, message: 'Please select dates!' }]
  };

  useEffect(() => {}, []);

  function disabledEndDate(current) {
    // Can not select days before today and today
    return (
      current &&
      form.getFieldValue('startDate') &&
      current < form.getFieldValue('startDate').endOf('day')
    );
  }

  function disableStartDate(current) {
    // Can not select days before today and today
    return (
      current &&
      form.getFieldValue('endDate') &&
      current > form.getFieldValue('endDate').startOf('day')
    );
  }

  return (
    <div className="flex flex-col pt-8">
      <div className="w-full text-center">
        <h2 className="text-lg">Create Availability</h2>
      </div>
      <div className="flex-1 mt-4">
        <Form
          form={form}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 16 }}
          layout="horizontal"
          title="Add Availability"
          onFinish={onSubmit}
        >
          <div className="px-5">
            <Form.Item label="Name" name="name" rules={[{ required: true }]}>
              <Input
                placeholder="ex. Weekend Availability"
                type="text"
                maxLength={25}
              />
            </Form.Item>

            <Form.Item
              label="Start Date"
              name="startDate"
              rules={[{ required: true }]}
            >
              <DatePicker disabledDate={disableStartDate} />
            </Form.Item>

            <Form.Item label="Time Range" name="time" {...rangeConfig}>
              <RangePicker use12Hours minuteStep={30} format={'h:mm a'} />
            </Form.Item>

            <Form.Item
              label="Days of Week"
              name="recurrentDays"
              rules={[{ required: true }]}
            >
              <Select mode="multiple" allowClear placeholder="Choose days">
                <Option value="MON">Monday</Option>
                <Option value="TUE">Tuesday</Option>
                <Option value="WED">Wednesday</Option>
                <Option value="THU">Thursday</Option>
                <Option value="FRI">Friday</Option>
                <Option value="SAT">Saturday</Option>
                <Option value="SUN">Sunday</Option>
              </Select>
            </Form.Item>
            <Form.Item
              name="endDate"
              label="End Date"
              style={{ marginBottom: 0 }}
              rules={[{ required: true }]}
            >
              <DatePicker disabledDate={disabledEndDate} />
            </Form.Item>
          </div>
          <div className="flex items-center mt-16">
            <Button
              type="ghost"
              onClick={onCancel}
              className="flex-1"
              style={{
                borderRadius: 0,
                height: 50,
                border: 0,
                backgroundColor: '#efefef',
                borderBottomLeftRadius: 2,
                borderBottomRightRadius: 0
              }}
            >
              CANCEL
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              className="flex-1"
              loading={isLoading}
              style={{
                borderRadius: 0,
                height: 50,
                border: 0,
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 2
              }}
            >
              CREATE
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
}
