import React, { useEffect, useState } from 'react';
import {
  Form,
  Input,
  Button,
  Switch,
  Select,
  DatePicker,
  TimePicker,
  Spin
} from 'antd';
import moment from 'moment';
import {
  createException,
  deleteException
} from '@campus/shared/services/AvailabilityService';
import { useToasts } from 'react-toast-notifications';

export function UpdateAvailabilityForm({
  editAvail,
  onCancel,
  onSubmit,
  isLoading,
  onRefresh
}) {
  const [form] = Form.useForm();
  const [switchLoading, setSwitchLoading] = useState(false);
  const { Option } = Select;
  const { RangePicker } = TimePicker;
  const dateFormat = 'YYYY/MM/DD';
  const timeFormat = 'H:mm';
  const { addToast } = useToasts();

  const rangeConfig = {
    rules: [{ type: 'array', required: true, message: 'Please select dates!' }]
  };

  function disabledEndDate(current) {
    // Can not select days before today and today
    return (
      current &&
      form.getFieldValue('startDate') &&
      current < form.getFieldValue('startDate').endOf('day')
    );
  }

  function disableStartDate(current) {
    // Can not select days before today and today
    return (
      current &&
      form.getFieldValue('endDate') &&
      current > form.getFieldValue('endDate').startOf('day')
    );
  }

  const onChange = async disable => {
    // create exception type cancel
    setSwitchLoading(true);
    if (disable) {
      const data = {
        type: 'CANCELLED',
        availabilityID: editAvail.id,
        date: editAvail.dateSelected
          ? editAvail.dateSelected.format('YYYY-MM-DD')
          : null,
        startTime: '00:00',
        duration: 60 * 24
      };
      await createException(data);
      addToast(
        'Availability disabled on ' +
          editAvail.dateSelected.format('MM/DD/YYYY'),
        {
          appearance: 'success'
        }
      );
      onRefresh();
    } else {
      // find existing exception
      const dateExceptions = editAvail.exceptions.items.filter(val =>
        moment(val.date, 'YYYY-MM-DD').isSame(editAvail.dateSelected, 'date')
      );
      if (dateExceptions.length > 0) {
        await deleteException(dateExceptions[0]);
        addToast(
          'Availability enabled on ' +
            editAvail.dateSelected.format('MM/DD/YYYY'),
          {
            appearance: 'success'
          }
        );
        onRefresh();
      }
    }
    setSwitchLoading(false);
  };

  return (
    <div className="flex flex-col pt-8">
      <div className="w-full text-center">
        <h2 className="text-lg">Update Availability</h2>
      </div>
      <div className="flex-1 mt-4">
        <Form
          form={form}
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 16 }}
          layout="horizontal"
          title="Add Availability"
          onFinish={onSubmit}
          initialValues={{
            name: editAvail.name,
            startDate: editAvail.startDate
              ? moment(editAvail.startDate, dateFormat)
              : moment(),
            endDate: editAvail.endDate
              ? moment(editAvail.endDate, dateFormat)
              : moment(),
            time: [
              moment.utc(editAvail.startTime, timeFormat).local(),
              moment
                .utc(editAvail.startTime, timeFormat)
                .add(editAvail.duration, 'minutes')
                .local()
            ],
            recurrentDays: editAvail.recurrentDays
          }}
        >
          <div className="px-5">
            <Form.Item label="Name" name="name" rules={[{ required: true }]}>
              <Input placeholder="Optional.." type="text" maxLength={25} />
            </Form.Item>

            <Form.Item
              label="Start Date"
              name="startDate"
              rules={[{ required: true }]}
            >
              <DatePicker disabledDate={disableStartDate} />
            </Form.Item>

            <Form.Item label="Time Range" name="time" {...rangeConfig}>
              <RangePicker use12Hours minuteStep={30} format={'h:mm a'} />
            </Form.Item>

            <Form.Item
              label="Days of Week"
              name="recurrentDays"
              rules={[{ required: true }]}
            >
              <Select mode="multiple" allowClear placeholder="Choose days">
                <Option value="MON">Monday</Option>
                <Option value="TUE">Tuesday</Option>
                <Option value="WED">Wednesday</Option>
                <Option value="THU">Thursday</Option>
                <Option value="FRI">Friday</Option>
                <Option value="SAT">Saturday</Option>
                <Option value="SUN">Sunday</Option>
              </Select>
            </Form.Item>

            <Form.Item label="End Date" style={{ marginBottom: 0 }}>
              <div className="flex items-start">
                <Form.Item name="endDate">
                  <DatePicker disabledDate={disabledEndDate} />
                </Form.Item>
              </div>
            </Form.Item>
            {editAvail?.dateSelected && (
              <Form.Item
                label={'Disable ' + editAvail.dateSelected.format('MM/DD')}
                name="isDisabled"
                style={{ marginBottom: 0 }}
              >
                <Switch
                  loading={switchLoading}
                  onChange={onChange}
                  checked={editAvail.exceptions.items
                    .map(v => v.date)
                    .includes(editAvail.dateSelected.format('YYYY-MM-DD'))}
                />
                <p className="text-gray-600 pt-3 text-xs">
                  Existing appointments will not be effected. Manage existing
                  appointments through the Booking Tab.
                </p>
              </Form.Item>
            )}
          </div>
          <div className="flex items-center mt-16">
            <Button
              type="ghost"
              onClick={onCancel}
              className="flex-1"
              style={{
                borderRadius: 0,
                height: 50,
                border: 0,
                backgroundColor: '#efefef',
                borderBottomLeftRadius: 2,
                borderBottomRightRadius: 0
              }}
            >
              CLOSE
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              className="flex-1"
              loading={isLoading}
              style={{
                borderRadius: 0,
                height: 50,
                border: 0,
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 2
              }}
            >
              UPDATE
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
}
