import React, { useState } from 'react';
import { Button, Form, Checkbox, Input } from 'antd';
import Header from './Header';
import { updateBusiness } from '@campus/shared/services/BusinessService';
import {
  getProvider,
  getProviderByID
} from '@campus/shared/services/UserService';
const CheckboxGroup = Checkbox.Group;

const Location = ({ onNext, onError }) => {
  const [form] = Form.useForm();
  const [showAddress, setShowAddress] = useState(false);
  const [loading, setLoading] = useState(false);

  const onFinish = async values => {
    console.log('input data:', form.getFieldValue('location'));
    // update business location preference
    setLoading(true);
    const provider = await getProvider();
    const myBusiness =
      provider.businesses?.items.length >= 0 && provider.businesses.items[0];
    const businessData = {
      id: myBusiness.id,
      _version: myBusiness._version,
      locationOptionOnSite: values.location?.includes('business') || false,
      locationOptionVisit: values.location?.includes('house') || false,
      shortLocation: values.address || null
    };
    const createResults = await updateBusiness(businessData);
    setLoading(true);
    // handle error
    if (createResults.error) return onError('Failed to update business');
    onNext();
  };

  const locationOptions = [
    { label: 'At Business', value: 'business' },
    { label: 'House Visit', value: 'house' }
  ];

  return (
    <div className="p-4 w-full md:w-1/2">
      <Header step={4} label={'Where do you prefer to meet?'} />
      <Form
        form={form}
        layout="vertical"
        onFinish={onFinish}
        onValuesChange={(changed, all) => {
          if (changed.location)
            setShowAddress(changed.location.includes('business'));
        }}
        size="large"
        requiredMark={false}
      >
        <Form.Item
          label="Place of Business"
          name="location"
          style={{ marginBottom: 8 }}
          rules={[{ required: true, message: 'Select at least one.' }]}
        >
          <CheckboxGroup options={locationOptions} />
        </Form.Item>

        {showAddress && (
          <Form.Item
            label="Business Address"
            name="address"
            rules={[{ required: true, message: 'Help people find you.' }]}
          >
            <Input placeholder="123 Street Ave, City ST..." />
          </Form.Item>
        )}

        <div className="mt-16">
          <Button
            name="submit"
            type="primary"
            className="w-[100px]"
            size="large"
            loading={loading}
            htmlType="submit"
          >
            Next
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default Location;
