import React, { useState, useEffect } from 'react';
import { Layout, Button, Menu, Modal, Row, Dropdown, Col } from 'antd';
import { DownOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons';
import {
  getUniversities,
  addUniversity,
  updateUniversity
} from '@campus/shared/services/UniversityService';
import { useToasts } from 'react-toast-notifications';
import { PrivateLayout } from '../../Layout/AppLayouts';
import ProviderCard from '../Admin/ProviderCard';
import { AddUniversityForm } from '../../Forms/AddUniversityForm';
import { UpdateUniversityForm } from '../../Forms/UpdateUniversityForm';
import BusinessCard from '../Admin/BusinessCard';

function AdminDash() {
  const { addToast } = useToasts();
  const [universities, setUniversities] = useState([]);
  const [selectedUniversity, setSelectedUniversity] = useState(null);
  const [tabSelected, setTabSelected] = useState('universities');
  const [addFormType, setFormType] = useState('');
  const [isAdding, setIsAdding] = useState(false);
  const [isModalvisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    loadUniversities();
  }, []);

  const loadUniversities = async () => {
    const data = await getUniversities();
    if (selectedUniversity === null) {
      data.length > 0 && setSelectedUniversity(data[0]);
    } else {
      const index = data
        .map(i => i.id)
        .findIndex(i => i === selectedUniversity.id);
      if (index >= 0) setSelectedUniversity(data[index]);
    }
    console.log('unviersities', data);
    setUniversities(data);
  };
  const onAddUniversity = () => {
    setFormType('add-university');
    setIsModalVisible(true);
  };

  const handleAddUniversity = async values => {
    try {
      setIsAdding(true);

      await addUniversity(values);
      setIsAdding(false);
      setIsModalVisible(false);
    } catch (error) {
      console.error(error.message);
    } finally {
      addToast('University created successfully', {
        appearance: 'success'
      });
      loadUniversities();
    }
  };

  const onEditUniversity = () => {
    setFormType('edit-university');
    setIsModalVisible(true);
  };

  const handleUpdateUniversity = async values => {
    try {
      setIsAdding(true);
      const universityData = {
        id: selectedUniversity.id,
        _version: selectedUniversity._version,
        name: values.name,
        domain: values.domain,
        description: values.description,
        utcOffset: values.utcOffset
      };
      await updateUniversity(universityData);
      setIsAdding(false);
      setIsModalVisible(false);
    } catch (error) {
      console.error(error.message);
    } finally {
      addToast('University updated successfully', {
        appearance: 'success'
      });
      loadUniversities();
    }
  };

  const UniversityMenu = () => (
    <Menu>
      {universities.map(item => (
        <Menu.Item key={item.id} onClick={() => setSelectedUniversity(item)}>
          <div className="py-2">{item.name}</div>
        </Menu.Item>
      ))}
      <Menu.Divider />
      <Menu.Item key="create" onClick={onAddUniversity}>
        <Button
          type="link"
          icon={<PlusOutlined className="" />}
          className="py-2"
        >
          Create University
        </Button>
      </Menu.Item>
    </Menu>
  );

  return (
    <PrivateLayout>
      <div className="overflow-scroll h-full">
        <div className="flex flex-col h-32 justify-between">
          <div className="flex items-center justify-between py-2 mx-4 my-2 border-gray-300 border">
            <Dropdown className="flex " overlay={UniversityMenu}>
              <Button type="link" onClick={e => e.preventDefault()}>
                {selectedUniversity?.name || 'Loading'}
                <DownOutlined className="ml-4" />
              </Button>
            </Dropdown>
            <Button
              type="link"
              shape="round"
              key="edit"
              icon={<EditOutlined key="edit" />}
              onClick={() => onEditUniversity()}
            >
              Edit
            </Button>
          </div>
          <Menu
            onClick={e => setTabSelected(e.key)}
            selectedKeys={[tabSelected]}
            mode="horizontal"
          >
            <Menu.Item key="universities">Businesses</Menu.Item>
            <Menu.Item key="providers">Providers</Menu.Item>
          </Menu>
        </div>

        <div className="p-4 bg-white overflow-auto">
          <Row gutter={[16, 16]}>
            <Col span={24} md={24}>
              {tabSelected === 'providers' ? (
                <ProviderCard
                  universities={universities}
                  selected={selectedUniversity}
                  setSelected={setSelectedUniversity}
                />
              ) : (
                <BusinessCard
                  universities={universities}
                  refreshUniversities={loadUniversities}
                  selected={selectedUniversity}
                  setSelected={setSelectedUniversity}
                />
              )}
            </Col>
          </Row>
        </div>

        <Modal
          visible={isModalvisible}
          width={800}
          footer={null}
          destroyOnClose={true}
          onCancel={() => setIsModalVisible(false)}
          closable={false}
          bodyStyle={{ padding: 0 }}
          style={{ borderRadius: 20 }}
        >
          {addFormType === 'add-university' ? (
            <AddUniversityForm
              onCancel={() => setIsModalVisible(false)}
              onSubmit={handleAddUniversity}
              isLoading={isAdding}
            />
          ) : addFormType === 'edit-university' ? (
            <UpdateUniversityForm
              onCancel={() => setIsModalVisible(false)}
              onSubmit={handleUpdateUniversity}
              university={selectedUniversity}
              isLoading={isAdding}
            />
          ) : null}
        </Modal>
      </div>
    </PrivateLayout>
  );
}

export default AdminDash;
