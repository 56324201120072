import {
  addVenueToUniversity,
  getVenue,
  updateVenue
} from "@campus/shared/services/VenueService";

export const actions = {
  setVenue: venue => ({ setState }) => {
    console.log("setVenue: ", venue);
    setState(draft => {
      draft.venue = venue;
    });
  },
  getFreshVenue: venueID => async ({ setState }) => {
    const { getVenue: newVenue } = await getVenue(venueID);
    setState(draft => {
      draft.venue = newVenue;
    });
  },
  updateVenue: venueData => async ({ setState, getState }) => {
    const { createVenue: venue } = await updateVenue(venueData, {
      name: "testing"
    });

    setState(draft => {
      const university = draft.universities.find(
        u => u.id === venue.universityID
      );
      university.venues.items.push(venue);
    });
  },
  addVenue: venuData => async ({ setState, getState }) => {
    const { createVenue: venue } = await addVenueToUniversity(venuData);

    setState(draft => {
      const university = draft.universities.find(
        u => u.id === venue.universityID
      );
      university.venues.items.push(venue);
    });
  }
};
