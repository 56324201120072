import React, { useState, useEffect } from 'react';
import { Table, Card, Modal, Menu, Button, Dropdown } from 'antd';
import { UserAddOutlined, DownOutlined } from '@ant-design/icons';
import { AddUserForm } from '../../Forms/AddUserForm';
import {
  addProvider,
  addToGroup,
  createUser,
  getAllProviders
} from '@campus/shared/services/UserManagementService';
import {
  addCustomer,
  createCustomer
} from '@campus/shared/services/UserService';
import { useToasts } from 'react-toast-notifications';

function ProviderCard({ selected }) {
  const { addToast } = useToasts();
  const [loading, setLoading] = useState(false);
  const [isAddingUser, setIsAddingUser] = useState(false);
  const [isModalvisible, setIsModalVisible] = useState(false);
  const [addFormType, setFormType] = useState('');
  const [userList, setUserList] = useState([]);
  const columns = [
    {
      title: 'Email',
      dataIndex: 'invitationalEmail',
      render: value => {
        if (!value) return 'Not Found';
        return value;
      }
    },
    {
      title: 'Name',
      render: value => {
        if (!value.firstName) return 'Not Found';
        return value.firstName + ' ' + value.lastName;
      }
    },
    {
      title: 'Phone Number',
      dataIndex: 'phoneNumber',
      render: value => {
        if (!value) return 'Not Found';
        return value;
      }
    },
    {
      title: 'cognitoID',
      dataIndex: ['userID']
    }
  ];

  useEffect(() => {
    selected && loadProviders();
  }, [selected]);

  const loadProviders = async () => {
    setLoading(true);
    const response = await getAllProviders(); // TODO update to query by university
    console.log('all providers', response.listProviders.items);
    const providersByUniversity = response.listProviders?.items.filter(
      item => item.universityID === selected.id
    );

    setUserList(providersByUniversity || []); // TODO update query
    setLoading(false);
  };

  const onAddUser = () => {
    setFormType('add-user');
    setIsModalVisible(true);
  };

  const handleAddUser = async ({ university, email }) => {
    try {
      setIsAddingUser(true);
      const { result, message } = await createUser({ email });
      const userID = result.User.Username;
      const userData = { universityID: university, userID };
      await addToGroup({ username: userID, groupname: 'providers' });
      await addProvider({ ...userData, email });
      await addToGroup({ username: userID, groupname: 'customers' });
      await addCustomer({
        userID,
        universityID: university
      });
      console.log('Provider and customer created.');
    } catch (error) {
      console.error(error);
      addToast(error.message, {
        appearance: 'error'
      });
    } finally {
      setIsAddingUser(false);
      setIsModalVisible(false);
      loadProviders();
    }
  };

  return (
    <div className="flex flex-col">
      <Button
        type="link"
        key="add"
        className="self-start mb-4"
        icon={<UserAddOutlined key="add" />}
        onClick={() => onAddUser()}
      >
        New Provider
      </Button>
      <Card
        loading={false}
        // title="Providers"
        bodyStyle={{ padding: 0 }}
        // extra={[
        //   <Button
        //     type="link"
        //     key="add"
        //     icon={<UserAddOutlined key="add" />}
        //     onClick={() => onAddUser()}
        //   >
        //     New Provider
        //   </Button>
        // ]}
      >
        <Table
          loading={loading}
          rowKey={record => record.id}
          columns={columns}
          pagination={false}
          dataSource={loading ? [] : userList}
        />
      </Card>

      <Modal
        visible={isModalvisible}
        width={800}
        footer={null}
        destroyOnClose={true}
        onCancel={() => setIsModalVisible(false)}
        closable={false}
        bodyStyle={{ padding: 0 }}
        style={{ borderRadius: 20 }}
      >
        {addFormType === 'add-user' ? (
          <AddUserForm
            onCancel={() => setIsModalVisible(false)}
            onSubmit={handleAddUser}
            isLoading={isAddingUser}
          />
        ) : null}
      </Modal>
    </div>
  );
}

export default ProviderCard;
