import React from 'react';
import { Modal } from 'antd';
export default function BasicModal({ onCancel, isVisible, children }) {
  return (
    <Modal
      visible={isVisible}
      footer={null}
      width={800}
      destroyOnClose={true}
      onCancel={onCancel}
      bodyStyle={{ padding: 0 }}
      style={{ borderRadius: 20 }}
    >
      {children}
    </Modal>
  );
}
