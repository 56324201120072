import React from 'react';

const BusinessStateContext = React.createContext();
const BusinessDispatchContext = React.createContext();

const initialState = {
  provider: null,
  providerLoading: true,
  business: null,
  loading: true
};

async function setBusiness(dispatch, business) {
  try {
    dispatch({ type: 'updateBusiness', payload: business });
  } catch (error) {
    console.log(error);
  }
}
async function setProvider(dispatch, provider) {
  try {
    dispatch({ type: 'updateProvider', payload: provider });
  } catch (error) {
    console.log(error);
  }
}

function userReducer(state, action) {
  switch (action.type) {
    case 'updateBusiness': {
      return { ...state, business: action.payload };
    }
    case 'updateProvider': {
      return { ...state, provider: action.payload, providerLoading: false };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function BusinessStateProvider({ children }) {
  const [state, dispatch] = React.useReducer(userReducer, initialState);
  return (
    <BusinessStateContext.Provider value={state}>
      <BusinessDispatchContext.Provider value={dispatch}>
        {children}
      </BusinessDispatchContext.Provider>
    </BusinessStateContext.Provider>
  );
}

function useBussinessState() {
  const context = React.useContext(BusinessStateContext);
  if (context === undefined) {
    throw new Error(
      'useApplicationState must be used within a BusinessStateProvider'
    );
  }
  return context;
}

function useBusinessDispatch() {
  const context = React.useContext(BusinessDispatchContext);
  if (context === undefined) {
    throw new Error(
      'useApplicationDispatch must be used within a BusinessStateProvider'
    );
  }
  return context;
}

export {
  BusinessStateProvider,
  useBussinessState,
  useBusinessDispatch,
  setBusiness,
  setProvider
};
