import {
  acceptOrder,
  rejectOrder,
  updateOrderStatus
} from '@campus/shared/services/OrderService';
import React, { useState, useEffect } from 'react';
import { List, Divider, Typography, Statistic, Row, Col, Drawer } from 'antd';
import { Button, Card } from 'antd';
import moment from 'moment';
import 'isomorphic-fetch';
import {
  getDateString,
  getLocalTimeslot
} from '@campus/shared/services/SharedService';
import {
  acceptBooking,
  cancelBooking,
  completeBooking,
  noshowBooking,
  rejectBooking
} from '@campus/shared/services/BookingService';

const STATUS_COLOR = Object.freeze({
  PENDING_VENDOR: 'text-yellow-500',
  IN_PROGRESS: 'text-blue-500',
  COMPLETED: 'text-green-500',
  CANCELLED: 'text-red-500'
});

const BOOKING_LOCATION = Object.freeze({
  onsite: 'On Site',
  atlocation: 'At Location',
  visit: 'House Visit'
});
const BOOKING_STATUS = Object.freeze({
  onsite: 'On Site',
  atlocation: 'At Location',
  homevisit: 'House Visit'
});

const BookingDetails = ({ booking, onClose, isVisible }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    booking && setLoading(false);
  }, [booking]);

  const onComplete = async () => {
    setLoading(true);
    await completeBooking(booking);
  };

  const onCancel = async () => {
    setLoading(true);
    await cancelBooking(booking);
  };

  const onNoShow = async () => {
    setLoading(true);
    await noshowBooking(booking);
  };

  const getActions = status => {
    switch (status) {
      case 'PLACED': {
        // if date and time passed, show complete else cancel
        const datePassed = () => {
          if (!booking) return;
          const fullDate = moment
            .utc(booking.time + ' ' + booking.date, 'HH:mm YYYY-MM-DD')
            .local();
          // const time = moment.utc(booking.time, 'HH:mm YYYY-MM-DD').local();
          // const date = moment(booking.date, 'YYYY-MM-DD');
          if (fullDate.isSame(moment(), 'day')) {
            return moment().isAfter(fullDate, 'minute');
          } else if (moment().isAfter(fullDate, 'day')) {
            return true;
          }
          return false;
        };

        if (datePassed()) {
          return (
            <div className="flex space-x-4">
              <Button
                className="flex-1"
                type="primary"
                size="large"
                loading={loading}
                onClick={onComplete}
              >
                Complete
              </Button>
              <Button
                className=""
                type="primary"
                size="large"
                danger
                loading={loading}
                onClick={onNoShow}
              >
                No Show
              </Button>
              <Button
                className=""
                type="primary"
                danger
                size="large"
                loading={loading}
                onClick={onCancel}
              >
                Cancel
              </Button>
            </div>
          );
        } else {
          return (
            <div className="flex space-x-4">
              <Button
                className="flex-1"
                type="primary"
                danger
                size="large"
                loading={loading}
                onClick={onCancel}
              >
                Cancel
              </Button>
            </div>
          );
        }
      }

      default:
        return null;
    }
  };

  return (
    <Drawer
      width={640}
      drawerStyle={{ touchAction: 'none' }}
      placement="right"
      closable={true}
      title={'Booking ID: #' + booking?.id.split('-')[0].toUpperCase()}
      footer={getActions(booking?.status)}
      onClose={onClose}
      visible={isVisible}
    >
      <div className="p-4 h-full flex flex-col">
        <div className="flex flex-col flex-1 overflow-y-auto space-y-6">
          <Row>
            <Col span={10}>
              <Statistic
                title="Appointment Date"
                value={moment(booking?.date, 'YYYY-MM-DD').format(
                  'ddd MM/DD/YYYY'
                )}
                loading={loading}
              />
            </Col>
            <Col span={8}>
              <Statistic
                title="Appointment Time"
                value={moment
                  .utc(booking?.time, 'HH:mm')
                  .local()
                  .format('h:mm a')}
                loading={loading}
              />
            </Col>
            <Col span={6}>
              <Statistic
                title="Location"
                value={
                  (booking?.locationName &&
                    BOOKING_LOCATION[booking?.locationName]) ||
                  'N/A'
                }
                loading={loading}
              />
            </Col>
          </Row>
          <Row>
            <Col span={10}>
              <Statistic
                title="Status"
                value={booking?.status || 'No Status'}
                loading={loading}
              />
            </Col>
            <Col span={14}>
              <Statistic
                title="Customer"
                value={
                  booking?.customer?.firstName +
                  ' ' +
                  booking?.customer?.lastName
                }
                loading={loading}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <Statistic
                title="Customer Notes"
                value={booking?.note || 'Notes go here!'}
                loading={loading}
              />
            </Col>
          </Row>
          <Divider />
          <List.Item
            actions={[
              <Typography.Text type="success" className="mr-4 text-lg">
                {'$' + (booking?.price / 100 || '56')}
              </Typography.Text>
            ]}
            className="border rounded-md px-4"
          >
            <List.Item.Meta
              className=" px-4"
              title={
                <Typography.Text strong className="text-lg">
                  {booking?.service?.name}
                </Typography.Text>
              }
              description={booking?.duration / 60 + ' hr'}
            />
          </List.Item>
        </div>
      </div>
    </Drawer>
  );
};

export default BookingDetails;
