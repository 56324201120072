import React, { useState } from 'react';
import { navigate } from '@reach/router';
import { Auth } from 'aws-amplify';

import { AppUser } from '../../Auth';

import { Button, Form, Input } from 'antd';
import { useToasts } from 'react-toast-notifications';
import { useAppStore } from '../../../stores/app';
import jwtDecode from 'jwt-decode';

const SetPassword = () => {
  const { addToast } = useToasts();
  const { setUser } = AppUser;
  const [form] = Form.useForm();
  const [isLoading, setLoading] = useState(false);
  const [state] = useAppStore();

  const login = async values => {
    try {
      setLoading(true);
      Auth.completeNewPassword(
        state.user, // the Cognito User Object
        values.password // the new password
      )
        .then(async user => {
          // at this time the user is logged in if no MFA required
          const userGroups =
            user.signInUserSession.accessToken.payload['cognito:groups'];
          const userInfo = {
            ...user.attributes,
            username: user.username,
            userGroups
          };
          setUser(userInfo);

          const session = await Auth.currentSession();
          const sessionIdInfo = jwtDecode(session.getIdToken().jwtToken);

          if (
            sessionIdInfo['cognito:groups'] &&
            sessionIdInfo['cognito:groups'].find(g => g === 'providers')
          ) {
            navigate('/app/signup');
          } else {
            navigate('/');
          }
        })
        .catch(e => {
          console.log(e);
        });
    } catch (err) {
      if (
        err.code === 'NotAuthorizedException' &&
        err.message ===
          'Temporary password has expired and must be reset by an administrator.'
      ) {
        console.log('go to setPassword');
      }

      addToast(err.message || err, { appearance: 'error' });
      console.log('error...: ', err.message || err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="h-full flex flex-col items-center justify-center">
      <div className="w-1/2 flex flex-col space-y-6">
        <div className="text-xl py-4">Create a New Password</div>
        <Form form={form} layout="vertical" onFinish={login} size="large">
          <Form.Item
            label="New Password"
            name="password"
            hasFeedback
            rules={[{ required: true, message: 'Please enter your password!' }]}
          >
            <Input.Password placeholder="Password..." />
          </Form.Item>
          <Form.Item
            name="confirm"
            label="Confirm Password"
            dependencies={['password']}
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Please confirm your password!'
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    'The two passwords that you entered do not match!'
                  );
                }
              })
            ]}
          >
            <Input.Password />
          </Form.Item>
          <div>
            <Button
              type="primary"
              shape="round"
              size="large"
              className="w-64"
              loading={isLoading}
              htmlType="submit"
            >
              Update password
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default SetPassword;
