import { API, graphqlOperation, Storage } from 'aws-amplify';
import { AmpAPI, configureAmplify } from './AmplifyService';
import { MUTATIONS, QUERIES, AWS_CONFIG } from '@campus/backend';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import moment from 'moment';
import {
  AvailabilitiesByBusinessQuery,
  CreateAvailabilityExceptionInput,
  CreateAvailabilityInput,
  DeleteAvailabilityExceptionInput,
  DeleteAvailabilityInput,
  DeleteServiceInput,
  GetAvailabilityQuery,
  ListAvailabilitysQuery,
  UpdateAvailabilityMutation,
  UpdateServiceMutation
} from '@campus/backend/API';

const DAY_OF_WEEK = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];

const {
  aws_user_files_s3_bucket_region: region,
  aws_user_files_s3_bucket: bucket
} = AWS_CONFIG;
configureAmplify();

const getAvailability = async businessID => {
  try {
    console.log('getAvailability: ', businessID);
    // const { data } = (await API.graphql(
    //   graphqlOperation(QUERIES.listAvailabilitys)
    // )) as GraphQLResult<ListAvailabilitysQuery>;
    // return data.listAvailabilitys.items;
    const { data } = (await API.graphql(
      graphqlOperation(QUERIES.availabilitiesByBusiness, { businessID })
    )) as GraphQLResult<AvailabilitiesByBusinessQuery>;
    return data['availabilitiesByBusiness'].items;
  } catch (e) {
    console.error(e);
  }
};

const createAvailability = async params => {
  try {
    const { data } = (await API.graphql(
      graphqlOperation(MUTATIONS.createAvailability, {
        input: params
      })
    )) as GraphQLResult<CreateAvailabilityInput>;
    return data;
  } catch (e) {
    console.error(e);
  }
};

const updateAvailability = async params => {
  try {
    const { data } = (await API.graphql(
      graphqlOperation(MUTATIONS.updateAvailability, {
        input: params
      })
    )) as GraphQLResult<UpdateAvailabilityMutation>;
    return data;
  } catch (e) {
    console.error(e);
  }
};

const deleteAvailability = async availability => {
  try {
    const { data } = (await API.graphql(
      graphqlOperation(MUTATIONS.deleteAvailability, {
        input: {
          id: availability.id,
          _version: availability._version
        }
      })
    )) as GraphQLResult<DeleteAvailabilityInput>;
    return data;
  } catch (e) {
    console.error(e);
  }
};

const createException = async params => {
  try {
    const { data } = (await API.graphql(
      graphqlOperation(MUTATIONS.createAvailabilityException, {
        input: params
      })
    )) as GraphQLResult<CreateAvailabilityExceptionInput>;
    return data;
  } catch (e) {
    console.error(e);
  }
};

const deleteException = async exception => {
  try {
    const { data } = (await API.graphql(
      graphqlOperation(MUTATIONS.deleteAvailabilityException, {
        input: {
          id: exception.id,
          _version: exception._version
        }
      })
    )) as GraphQLResult<DeleteAvailabilityExceptionInput>;
    return data;
  } catch (e) {
    console.error(e);
  }
};

const updateService = async params => {
  try {
    const { data } = (await API.graphql(
      graphqlOperation(MUTATIONS.updateService, {
        input: params
      })
    )) as GraphQLResult<UpdateServiceMutation>;
    return data;
  } catch (e) {
    console.error(e);
  }
};

const deleteService = async service => {
  try {
    const { data } = (await API.graphql(
      graphqlOperation(MUTATIONS.deleteService, {
        input: {
          id: service.id,
          _version: service._version
        }
      })
    )) as GraphQLResult<DeleteServiceInput>;
    return data;
  } catch (e) {
    console.error(e);
  }
};

const isValidDate = (avail, date) => {
  let valid = false;

  // check if event has end date
  if (avail.endDate) {
    // check against start and end date
    if (date.isBetween(avail.startDate, avail.endDate, 'day', '[]'))
      valid = true;
  } else {
    // check against start date
    if (date.isAfter(avail.startDate)) valid = true;
  }

  // check against day of week
  if (!avail.recurrentDays?.includes(DAY_OF_WEEK[date.day()])) valid = false;

  return valid;
};

export {
  getAvailability,
  createAvailability,
  updateAvailability,
  deleteAvailability,
  createException,
  deleteException,
  updateService,
  deleteService,
  isValidDate
};
