import { Button, Card, Popconfirm, List, Image } from 'antd';
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import {
  useBusinessDispatch,
  useBussinessState
} from '../../../contexts/BusinessContext';
import {
  getProfilePicture,
  getProvider,
  getProviderByID,
  updateProvider
} from '@campus/shared/services/UserService';
import { useAppStore } from '../../../stores/app';
import { getCurrentUser } from '../../Auth/AppUser';
import { UpdateProviderImage } from '../../Forms/UpdateProviderImage';
import BasicModal from '../../Dialogs/BasicModal';
import { UpdateBusinessForm } from '../../Forms/UpdateBusinessForm';
import { useToasts } from 'react-toast-notifications';
import { UpdateBusinessImage } from '../../Forms/UpdateBusinessImage';
import {
  createService,
  deleteService,
  getBusiness,
  getBusinessImage,
  getServices,
  updateBusiness,
  updateService
} from '@campus/shared/services/BusinessService';
import { AddServiceForm } from '../../Forms/AddServiceForm';
import { UpdateServiceForm } from '../../Forms/UpdateServiceForm';

function ServiceCard() {
  const dispatch = useBusinessDispatch();
  const { business, provider, businessLoading } = useBussinessState();
  const [loadingServices, setLoadingServices] = useState(true);
  const [modalLoading, setModalLoading] = useState(false);
  const [visible, setVisible] = useState({ show: false, id: null });
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [services, setServices] = useState([]);
  const [showModal, setShowModal] = useState('');
  const [selectedService, setSelectedService] = useState(null);
  const { addToast } = useToasts();

  useEffect(() => {
    business && loadServices();
  }, [business]);

  const loadServices = async () => {
    setLoadingServices(true);
    const myServices = await getServices(business.id);
    setServices(myServices);
    setLoadingServices(false);
  };

  const onCreateService = async values => {
    setModalLoading(true);
    // create data
    const data = {
      businessID: business.id,
      name: values.name,
      description: values.description || '',
      duration: parseInt(values.duration),
      price: parseFloat(values.price).toFixed(2) * 100,
      active: true
    };
    // make request
    const result = await createService(data);
    // check if success
    if (result) console.log(result);
    // update loading and hide modal
    setModalLoading(false);
    setShowModal('');
    loadServices();
  };

  const onEditService = async values => {
    setModalLoading(true);
    // new data
    const data = {
      id: selectedService.id,
      _version: selectedService._version,
      name: values.name,
      description: values.description || '',
      duration: parseInt(values.duration),
      price: parseFloat(values.price).toFixed(2) * 100,
      active: true
    };
    // make request
    const result = await updateService(data);
    // check if success
    if (result.errors) {
      console.error(result.errors);
      alert('Error Creating Service');
    }
    // update loading and hide modal
    setModalLoading(false);
    setShowModal('');
    setSelectedService(null);
    loadServices();
  };

  const onDeleteService = async service => {
    setConfirmLoading(true);
    // make request
    const result = await deleteService(service);
    // check if success
    if (result.errors) console.error(result.errors);
    // update UI
    setConfirmLoading(false);
    setVisible({ show: false, id: null });
    loadServices();
  };

  return (
    <>
      <Card
        loading={loadingServices}
        title="Services"
        extra={[
          <Button
            type="link"
            icon={<PlusOutlined />}
            key="addService"
            onClick={() => setShowModal('addService')}
          >
            Service
          </Button>
        ]}
      >
        <List
          itemLayout="horizontal"
          dataSource={services}
          rowKey={item => item.id}
          renderItem={item => (
            <List.Item
              actions={[
                <Button
                  type="link"
                  key="edit"
                  icon={<EditOutlined key="edit" />}
                  onClick={() => {
                    setSelectedService(item);
                    setShowModal('updateService');
                  }}
                >
                  Edit
                </Button>,
                <Popconfirm
                  key="delete"
                  title="Confirm Delete Service"
                  visible={visible.show && visible.id === item.id}
                  placement="left"
                  onConfirm={() => onDeleteService(item)}
                  okButtonProps={{ loading: confirmLoading }}
                  onCancel={() => setVisible({ show: false, id: null })}
                >
                  <Button
                    type="link"
                    danger
                    key="delete"
                    icon={<DeleteOutlined />}
                    onClick={() => {
                      setVisible({ show: true, id: item.id });
                    }}
                  >
                    Delete
                  </Button>
                </Popconfirm>
              ]}
            >
              <List.Item.Meta
                title={item.name}
                description={
                  parseInt(item.duration) / 60 + 'hr - ' + item.description
                }
              />
            </List.Item>
          )}
        />
      </Card>
      <BasicModal isVisible={showModal} onCancel={() => setShowModal('')}>
        {showModal == 'addService' ? (
          <AddServiceForm
            onCancel={() => setShowModal('')}
            onSubmit={onCreateService}
            isLoading={modalLoading}
          />
        ) : showModal === 'updateService' ? (
          <UpdateServiceForm
            onCancel={() => setShowModal('')}
            onSubmit={onEditService}
            isLoading={modalLoading}
            selectedService={selectedService}
          />
        ) : null}
      </BasicModal>
    </>
  );
}

export default ServiceCard;
