import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Radio, Select } from 'antd';

import { getUniversities } from '@campus/shared/services/UniversityService';

export function AddUserForm({ onCancel, onSubmit, isLoading }) {
  const [form] = Form.useForm();
  const [venues, setVenues] = useState([]);
  const [universities, setUniversities] = useState([]);
  const { Option } = Select;

  useEffect(() => {
    loadUniversities();
  }, []);

  const loadUniversities = async () => {
    const allUniversities = await getUniversities();
    const universities = allUniversities.map(university => ({
      text: university.name,
      value: university.id,
      venues: university.venues.items.map(venue => ({
        text: venue.name,
        value: venue.id
      }))
    }));
    setUniversities(universities);
  };

  return (
    <div className="flex flex-col pt-8">
      <div className="w-full text-center">
        <h2 className="text-lg">New User(s)</h2>
        {universities.length == 0 && <p id="loading">Loading...</p>}
      </div>
      <div className="flex-1  mt-4">
        <Form form={form} layout="vertical" onFinish={onSubmit}>
          <div className="px-5">
            <Form.Item
              label="Email"
              name="email"
              rules={[{ type: 'email', required: true }]}
            >
              <Input
                placeholder="example@domain.edu"
                type="email"
                maxLength={50}
              />
            </Form.Item>

            <Form.Item
              label="Select a University"
              name="university"
              rules={[{ required: true }]}
            >
              <Select
                placeholder="Select"
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {universities.map(d => (
                  <Option key={d.value}>{d.text}</Option>
                ))}
              </Select>
            </Form.Item>
          </div>

          <div className="flex items-center mt-16">
            <Button
              className="flex-1"
              onClick={onCancel}
              style={{
                borderRadius: 0,
                height: 50,
                backgroundColor: '#efefef',
                border: 0,
                borderBottomLeftRadius: 2,
                borderBottomRightRadius: 0
              }}
            >
              CANCEL
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              className="flex-1"
              loading={isLoading}
              style={{
                borderRadius: 0,
                height: 50,
                border: 0,
                borderBottomLeftRadius: 0,
                borderBottomRightRadius: 2
              }}
            >
              SEND INVITE
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
}
