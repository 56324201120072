import { API, graphqlOperation } from 'aws-amplify';
import { AmpAPI, configureAmplify } from './AmplifyService';
import { MUTATIONS, QUERIES, AWS_CONFIG, SUBS } from '@campus/backend';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import {
  BookingsByBusinessQuery,
  BookingsByCustomerQuery,
  UpdateBookingMutation,
  UpdateServiceMutation
} from '@campus/backend/API';

const {
  aws_user_files_s3_bucket_region: region,
  aws_user_files_s3_bucket: bucket
} = AWS_CONFIG;
configureAmplify();

const getBookingByBusiness = async input => {
  try {
    const { data } = (await API.graphql(
      graphqlOperation(QUERIES.bookingsByBusiness, {
        businessID: input
      })
    )) as GraphQLResult<BookingsByBusinessQuery>;
    return data.bookingsByBusiness.items;
  } catch (e) {
    console.error(e);
  }
};

const getBookingByBusinessAndDate = async (input, startDate, endDate) => {
  try {
    const { data } = (await API.graphql(
      graphqlOperation(QUERIES.bookingsByBusiness, {
        businessID: input,
        filter: {
          date: {
            ge: startDate,
            lt: endDate
          }
        }
      })
    )) as GraphQLResult<BookingsByBusinessQuery>;
    return data.bookingsByBusiness.items;
  } catch (e) {
    console.error(e);
  }
};

const onUpdateBookingByBusinessID$ = businessID => {
  return API.graphql(
    graphqlOperation(SUBS.onUpdateBookingByBusiness, {
      businessID: businessID
    })
  );
};

const getBookingByCustomer = async input => {
  try {
    const { data } = (await API.graphql(
      graphqlOperation(QUERIES.bookingsByCustomer, { customerID: input })
    )) as GraphQLResult<BookingsByCustomerQuery>;
    return data.bookingsByCustomer.items;
  } catch (e) {
    console.error(e);
  }
};

const createBooking = async booking => {
  const result = await AmpAPI.patch('CampusAPI', '/booking', {
    body: {
      bookingID: booking.id,
      bookingVersion: booking._version,
      status: 'COMPLETED'
    }
  });
  return result;
};

const completeBooking = async booking => {
  console.log({
    bookingID: booking.id,
    bookingVersion: booking._version,
    status: 'COMPLETED'
  });
  const result = await AmpAPI.patch('CampusAPI', '/booking', {
    body: {
      bookingID: booking.id,
      bookingVersion: booking._version,
      status: 'COMPLETED'
    }
  });
  return result;
};

const cancelBooking = async booking => {
  const result = await AmpAPI.patch('CampusAPI', '/booking', {
    body: {
      bookingID: booking.id,
      bookingVersion: booking._version,
      status: 'CANCELLED'
    }
  });
  return result;
};

const noshowBooking = async booking => {
  const result = await AmpAPI.patch('CampusAPI', '/booking', {
    body: {
      bookingID: booking.id,
      bookingVersion: booking._version,
      status: 'NO_SHOW'
    }
  });
  console.log(result);
  return result;
};

// HANDLED BY LAMBDA
const sendPushNotification = async (status, booking) => {
  // compose message
  let message = '';
  switch (status) {
    case 'ACCEPTED':
      message = `your appointment #${
        booking.id.split('-')[0]
      } has been Accepted.`;
    case 'COMPLETED':
      message = `your appointment #${
        booking.id.split('-')[0]
      } has been marked Completed.`;
    case 'REJECTED':
      message = `your appointment #${
        booking.id.split('-')[0]
      } has been Rejected. Open for more details.`;
    case 'CANCELLED':
      message = `your appointment #${
        booking.id.split('-')[0]
      } has been Cancelled. Open for more details.`;
    default:
      message = `your appointment #${
        booking.id.split('-')[0]
      } has a new status ${status}.`;
  }
  // send push request
  const resp = await AmpAPI.post('CampusAPI', '/notification', {
    body: {
      data: booking.customer.userID,
      message: message,
      name: booking.customer.firstName
    }
  });
  console.log('Successfully sent notification.', resp);
};

export {
  completeBooking,
  cancelBooking,
  noshowBooking,
  getBookingByBusiness,
  getBookingByBusinessAndDate,
  getBookingByCustomer,
  onUpdateBookingByBusinessID$
};
