import React, { useEffect, useState } from 'react';
import { Form, Input, Card, Row, Col, Button, Space } from 'antd';
import { Auth } from 'aws-amplify';
import {
  getCustomer,
  getDirector,
  getProvider,
  getStaff,
  getVendor,
  updateCustomer,
  updateDirector,
  updateProvider,
  updateStaff,
  updateVendor
} from '@campus/shared/services/UserService';
import { getUniversity } from '@campus/shared/services/UniversityService';

export function EditAccount({}) {
  const [currentUser, setCurrentUser] = useState({});
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    setLoading(true);
    const user = await Auth.currentAuthenticatedUser();
    const userGroups =
      user.signInUserSession.accessToken.payload['cognito:groups'];
    let savedUser = null;
    // --- PROVIDER --- //
    if (userGroups.includes('admins')) {
      savedUser = await getCustomer();
      console.log(savedUser);
    } else if (userGroups.includes('providers')) {
      savedUser = await getProvider();
    }
    // --- ADMIN --- //
    console.log(user, userGroups);
    const univ =
      savedUser?.universityID && (await getUniversity(savedUser.universityID));
    setCurrentUser({
      email: user.attributes.email,
      userRoles: userGroups,
      firstName: savedUser?.firstName,
      lastName: savedUser?.lastName,
      phoneNumber: savedUser?.phoneNumber,
      university: univ?.name
    });

    setLoading(false);
  };

  const onFinish = async values => {
    console.log('Received values of form: ', values);

    setLoading(true);
    setEdit(false);

    let params = {
      firstName: values.firstName,
      lastName: values.lastName,
      phoneNumber: values.phone
    };

    // --- PROVIDER --- //
    if (userRoles.includes('providers')) {
      const result = await updateProvider(params);

      if (result.error) {
        console.log('Error: ', result.error);
      }
    }
    // --- ADMIN --- //
    if (userRoles.includes('admins')) {
      const result = await updateCustomer(params);

      if (result.error) {
        console.log('Error: ', result.error);
      }
    }

    fetchUserData();
  };

  const formatPhone = num => {
    let formatted = num.replace(
      /(\d{1,2})(\d{1})?(\d{1,3})?(\d{1,4})?/,
      function(_, p1, p2, p3, p4) {
        let output = '';
        if (p1) output = `(${p1}`;
        if (p2) output += `${p2})`;
        if (p3) output += ` ${p3}`;
        if (p4) output += ` ${p4}`;
        return output;
      }
    );
    return formatted;
  };

  const CardRow = ({ label, info }) => (
    <p>
      <span className="text-gray-600">{label}: </span>
      {info}
    </p>
  );

  const {
    firstName,
    lastName,
    email,
    phoneNumber,
    university,
    userRoles
  } = currentUser;

  return (
    <div className="p-5">
      <Row>
        {edit ? (
          <Col span={24} lg={{ span: 12 }} className="p-4">
            <Form layout="vertical" name="complex-form" onFinish={onFinish}>
              <Form.Item
                label="First Name"
                name="firstName"
                style={{ marginBottom: 12 }}
                initialValue={firstName}
              >
                <Input placeholder="Type here..." />
              </Form.Item>
              <Form.Item
                label="Last Name"
                name="lastName"
                style={{ marginBottom: 12 }}
                initialValue={lastName}
              >
                <Input placeholder="Type here..." />
              </Form.Item>
              <Form.Item
                label="Phone Number"
                name="phone"
                style={{ marginBottom: 12 }}
                initialValue={phoneNumber}
              >
                <Input
                  maxLength={10}
                  addonBefore={<p className="p-0 m-0">+1</p>}
                  placeholder="123-123-1234"
                />
              </Form.Item>

              <Form.Item label=" " colon={false}>
                <Space>
                  <Button type="primary" htmlType="submit">
                    Save
                  </Button>
                  <Button
                    type="secondary"
                    onClick={e => {
                      setEdit(false);
                    }}
                  >
                    Cancel
                  </Button>
                </Space>
              </Form.Item>
            </Form>
          </Col>
        ) : (
          <Col span={24} lg={{ span: 12 }} className="p-4">
            <Card
              title="My Account"
              loading={loading}
              extra={<a onClick={() => setEdit(true)}>Edit</a>}
            >
              {firstName && (
                <CardRow
                  label="Name"
                  info={firstName + (lastName && ` ${lastName}`)}
                />
              )}
              {phoneNumber && (
                <CardRow
                  label="Phone Number"
                  info={'+1 ' + formatPhone(phoneNumber)}
                />
              )}
              <CardRow label="Email" info={email} />
              <CardRow label="University" info={university} />
              <CardRow
                label="Role"
                info={userRoles && userRoles.map((r, i) => (i ? ', ' + r : r))}
              />
            </Card>
          </Col>
        )}
      </Row>
    </div>
  );
}
