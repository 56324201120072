import React from 'react';
import 'modern-normalize/modern-normalize.css';
import { useNavigate } from '@reach/router';
import {
  SettingOutlined,
  LogoutOutlined,
  LeftOutlined
} from '@ant-design/icons';
import Img from '../../assets/images/ccLogo.png';
import { Button, Layout } from 'antd';
import { getCurrentUser, setUser } from '../Auth/AppUser';
import { Auth } from 'aws-amplify';
const { Header, Content } = Layout;

function PrivateLayout({ children, isUserNav }) {
  const navigate = useNavigate();
  const isProvdierView =
    getCurrentUser().userGroups.includes('admins') &&
    window.location.pathname.includes('provider');
  return (
    <Layout className="h-screen">
      <Header
        style={{ backgroundColor: 'white', padding: 0 }}
        className="fixed w-screen z-20 h-full flex justify-between items-center"
      >
        <div className="flex items-center">
          <img
            src={Img}
            className="ml-4 h-14 w-14  object-cover"
            height={50}
            width={50}
          />
          <div className="ml-6 flex flex-col justify-center">
            <div className="m-0 p-0 md:text-lg md:font-medium leading-none ">
              Campus Concierge
            </div>
            <div className="text-xs m-0 p-0 pt-1 text-gray-500 leading-none">
              {getCurrentUser().userGroups.includes('admins')
                ? 'Admin'
                : 'Provider'}
            </div>
          </div>
        </div>
        <div className="mr-4">
          {isProvdierView ? (
            <Button
              icon={<LogoutOutlined />}
              onClick={() => navigate('/app/admin')}
            >
              Back To Admin
            </Button>
          ) : (
            <Button
              icon={<SettingOutlined />}
              onClick={() => navigate('/app/settings')}
            >
              Settings
            </Button>
          )}
        </div>
      </Header>
      <Content className="pt-16 bg-white">{children}</Content>
    </Layout>
  );
}

function SettingsLayout({ children }) {
  return (
    <Layout className="h-screen">
      <Header
        style={{ backgroundColor: 'white', padding: 0 }}
        className="fixed w-screen z-20 flex justify-between h-full items-center border-b border-gray-200 shadow-sm"
      >
        <div className="flex items-center ml-6">
          <Button
            type="link"
            style={{ marginBottom: '-2px' }}
            onClick={() => window.history.back()}
            icon={<LeftOutlined />}
          />

          <p className="m-0 p-0 ml-6 text-lg font-bold">Settings</p>
        </div>
      </Header>
      <Content className="h-full overflow-y-auto mt-16 bg-white">
        {children}
      </Content>
    </Layout>
  );
}

function Layout2({ children, isUserNav }) {
  return (
    <div className="flex flex-col h-screen">
      <div className="flex-1 overflow-hidden">{children}</div>
    </div>
  );
}

function LayoutScroll({ children }) {
  return <div className="h-full overflow-y-scroll">{children}</div>;
}

function AppContent({ children }) {
  return (
    <div className="app-content-100">
      <div className="container">{children}</div>
    </div>
  );
}

function SplitLayout({ children, rightComponent, showLogout = false }) {
  const navigate = useNavigate();

  const onLogout = async () => {
    setUser({});
    await Auth.signOut();
    navigate('/app/signin');
  };

  return (
    <div className="flex flex-col h-screen">
      <Header
        style={{ backgroundColor: 'white', padding: 0 }}
        className="w-screen z-20 flex justify-between border-b border-gray-200 items-center"
      >
        <div className="flex items-center flex-1 justify-between">
          <div className="flex items-center">
            <img
              src={Img}
              className="ml-4 h-14 w-14  object-cover"
              height={50}
              width={50}
            />
            <div className="ml-6 flex flex-col justify-center">
              <div className="m-0 p-0 md:text-lg md:font-medium leading-none ">
                Campus Concierge
              </div>
            </div>
          </div>
          {showLogout && (
            <Button type="ghost" onClick={onLogout} className="mr-4">
              Log out
            </Button>
          )}
        </div>
      </Header>
      <div className="flex-1 flex overflow-hidden">
        <div className="flex-1 overflow-scroll ">{children}</div>
        <div className="hidden lg:block flex-1">{rightComponent}</div>
      </div>
    </div>
  );
}

export {
  AppContent,
  SplitLayout,
  LayoutScroll,
  Layout2,
  PrivateLayout,
  SettingsLayout
};
