import React, { useEffect, useState } from 'react';
import { useNavigate } from '@reach/router';
import { AppUser } from '../Auth';
import { BusinessStateProvider } from '../../contexts/BusinessContext';
import PrivateNav from '../Nav/PrivateNav';
import { PrivateLayout } from '../Layout/AppLayouts';

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  const navigate = useNavigate();
  const [loading, setloading] = useState(true);

  useEffect(() => {
    const { isLoggedIn } = AppUser;
    if (!isLoggedIn()) {
      console.log('not authorized  -> /signin');
      navigate('/app/signin');
    } else {
      setloading(false);
    }
  }, []);

  return loading ? null : (
    <BusinessStateProvider>
      <Component {...rest} />
    </BusinessStateProvider>
  );
};

export default PrivateRoute;
