import { API, Auth, graphqlOperation } from 'aws-amplify';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import {
  CreateDelivererInput,
  CreateDirectorInput,
  CreateStaffInput,
  CreateVendorInput,
  CreateProviderInput,
  DeliverersByUniversityQuery,
  ListDeliverersQuery,
  ListDirectorsQuery,
  ListStaffsQuery,
  ListVendorsQuery,
  StaffByUniversityQuery,
  StaffByUserQuery,
  UpdateDelivererInput,
  UpdateStaffInput,
  UpdateVendorInput,
  VendorsByUniversityQuery,
  VendorsByUserQuery,
  ProvidersByUserQuery,
  ListProvidersQuery,
  UpdateProviderInput
} from '@campus/backend/API';
import { MUTATIONS, QUERIES } from '@campus/backend';
import { AccountStatus } from '@campus/backend/models';
import { AmpAPI } from './AmplifyService';

const createUser = async ({ email }) => {
  let apiName = 'AdminQueries';
  let path = '/createUser';
  let myInit = {
    body: {
      email
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`
    }
  };
  return await AmpAPI.post(apiName, path, myInit);
};

const addToGroup = async ({ username, groupname = 'directors' }) => {
  let apiName = 'AdminQueries';
  let path = '/addUserToGroup';
  let myInit = {
    body: {
      username,
      groupname
    },
    headers: {
      'Content-Type': 'application/json',
      Authorization: `${(await Auth.currentSession())
        .getAccessToken()
        .getJwtToken()}`
    }
  };
  return await AmpAPI.post(apiName, path, myInit);
};

const addDirector = async ({ userID, universityID, email }) => {
  try {
    const { data } = (await API.graphql(
      graphqlOperation(MUTATIONS.createDirector, {
        input: {
          userID,
          universityID,
          status: AccountStatus.ACTIVE,
          invitationalEmail: email
        }
      })
    )) as GraphQLResult<CreateDirectorInput>;
    return data;
  } catch (e) {
    console.error(e);
  }
};

const addProvider = async (
  { userID, universityID, email },
  isAdmin = false
) => {
  try {
    const { data } = (await API.graphql(
      graphqlOperation(MUTATIONS.createProvider, {
        input: {
          userID,
          universityID,
          status: isAdmin ? AccountStatus.ACTIVE : AccountStatus.PENDING,
          invitationalEmail: email
        }
      })
    )) as GraphQLResult<CreateProviderInput>;
    return data;
  } catch (e) {
    console.error(e);
  }
};

const addVendor = async (
  { userID, venueID, universityID, email },
  isAdmin = false
) => {
  try {
    const { data } = (await API.graphql(
      graphqlOperation(MUTATIONS.createVendor, {
        input: {
          userID,
          venueID,
          universityID,
          status: isAdmin ? AccountStatus.ACTIVE : AccountStatus.PENDING,
          invitationalEmail: email
        }
      })
    )) as GraphQLResult<CreateVendorInput>;
    return data;
  } catch (e) {
    console.error(e);
  }
};

const addStaff = async (
  { userID, venueID, universityID, email },
  isAdmin = false
) => {
  try {
    const { data } = (await API.graphql(
      graphqlOperation(MUTATIONS.createStaff, {
        input: {
          userID,
          venueID,
          universityID,
          invitationalEmail: email,
          status: isAdmin ? AccountStatus.ACTIVE : AccountStatus.PENDING
        }
      })
    )) as GraphQLResult<CreateStaffInput>;
    return data;
  } catch (e) {
    console.error(e);
  }
};

const addDeliverer = async ({ userID, universityID, email }, isAdmin) => {
  try {
    const { data } = (await API.graphql(
      graphqlOperation(MUTATIONS.createDeliverer, {
        input: {
          userID,
          universityID,
          status: isAdmin ? AccountStatus.ACTIVE : AccountStatus.PENDING,
          invitationalEmail: email
        }
      })
    )) as GraphQLResult<CreateDelivererInput>;
    return data;
  } catch (e) {
    console.error(e);
  }
};

const getAllDeliverers = async () => {
  try {
    const { data } = (await API.graphql(
      graphqlOperation(QUERIES.listDeliverers)
    )) as GraphQLResult<ListDeliverersQuery>;
    return data;
  } catch (e) {
    console.error(e);
  }
};
const getDeliverersByUniversity = async universityID => {
  try {
    const { data } = (await API.graphql(
      graphqlOperation(QUERIES.deliverersByUniversity, {
        universityID
      })
    )) as GraphQLResult<DeliverersByUniversityQuery>;
    return data;
  } catch (e) {
    console.error(e);
  }
};

const getAllDirectors = async () => {
  try {
    const { data } = (await API.graphql(
      graphqlOperation(QUERIES.listDirectors)
    )) as GraphQLResult<ListDirectorsQuery>;
    return data;
  } catch (e) {
    console.error(e);
  }
};

const getAllVendors = async () => {
  try {
    const { data } = (await API.graphql(
      graphqlOperation(QUERIES.listVendors)
    )) as GraphQLResult<ListVendorsQuery>;
    return data;
  } catch (e) {
    console.error(e);
  }
};
const getAllStaff = async () => {
  try {
    const { data } = (await API.graphql(
      graphqlOperation(QUERIES.listStaffs)
    )) as GraphQLResult<ListStaffsQuery>;
    return data;
  } catch (e) {
    console.error(e);
  }
};
const getAllProviders = async () => {
  try {
    const { data } = (await API.graphql(
      graphqlOperation(QUERIES.listProviders) // TODO: change to listOwners
    )) as GraphQLResult<ListProvidersQuery>;
    return data;
  } catch (e) {
    console.error(e);
  }
};

const getVendorsByUniversity = async universityID => {
  try {
    const { data } = (await API.graphql(
      graphqlOperation(QUERIES.vendorsByUniversity, {
        universityID
      })
    )) as GraphQLResult<VendorsByUniversityQuery>;
    return data;
  } catch (e) {
    console.error(e);
  }
};
const getStaffByUniversity = async universityID => {
  try {
    const { data } = (await API.graphql(
      graphqlOperation(QUERIES.staffByUniversity, {
        // TODO staffByUniversity
        universityID
      })
    )) as GraphQLResult<StaffByUniversityQuery>;
    return data;
  } catch (e) {
    console.error(e);
  }
};

const acceptDeliverer = async deliverer => {
  try {
    const { result, message } = await createUser({
      email: deliverer.invitationalEmail
    });
    const userID = result.User.Username;

    await addToGroup({ username: userID, groupname: 'deliverers' });
    const { data } = (await API.graphql(
      graphqlOperation(MUTATIONS.updateDeliverer, {
        input: {
          id: deliverer.id,
          userID,
          // owner: userID,
          status: AccountStatus.ACTIVE,
          _version: deliverer._version
        }
      })
    )) as GraphQLResult<UpdateDelivererInput>;
    return data;
  } catch (e) {
    console.error(e);
  }
};

const updateDeliverer = async (deliverer, status) => {
  try {
    const { data } = (await API.graphql(
      graphqlOperation(MUTATIONS.updateDeliverer, {
        input: {
          id: deliverer.id,
          status: status,
          _version: deliverer._version
        }
      })
    )) as GraphQLResult<UpdateDelivererInput>;
    return data;
  } catch (e) {
    console.error(e);
  }
};

const rejectDeliverer = async deliverer => {
  try {
    const { data } = (await API.graphql(
      graphqlOperation(MUTATIONS.updateDeliverer, {
        input: {
          id: deliverer.id,
          status: AccountStatus.REJECTED,
          _version: deliverer._version
        }
      })
    )) as GraphQLResult<UpdateVendorInput>;
    return data;
  } catch (e) {
    console.error(e);
  }
};

const acceptVendor = async vendor => {
  try {
    const { result, message } = await createUser({
      email: vendor.invitationalEmail
    });
    const userID = result.User.Username;
    await addToGroup({ username: userID, groupname: 'vendors' });
    const { data } = (await API.graphql(
      graphqlOperation(MUTATIONS.updateVendor, {
        input: {
          id: vendor.id,
          userID,
          status: AccountStatus.ACTIVE,
          _version: vendor._version
        }
      })
    )) as GraphQLResult<UpdateVendorInput>;
    return data;
  } catch (e) {
    console.error(e);
  }
};

const acceptStaff = async staff => {
  try {
    const { result, message } = await createUser({
      email: staff.invitationalEmail
    });
    const userID = result.User.Username;
    await addToGroup({ username: userID, groupname: 'staff' });
    const { data } = (await API.graphql(
      graphqlOperation(MUTATIONS.updateStaff, {
        input: {
          id: staff.id,
          userID,
          status: AccountStatus.ACTIVE,
          _version: staff._version
        }
      })
    )) as GraphQLResult<UpdateStaffInput>;
    return data;
  } catch (e) {
    console.error(e);
  }
};

const rejectVendor = async vendor => {
  try {
    const { data } = (await API.graphql(
      graphqlOperation(MUTATIONS.updateVendor, {
        input: {
          id: vendor.id,
          status: AccountStatus.REJECTED,
          _version: vendor._version
        }
      })
    )) as GraphQLResult<UpdateVendorInput>;
    return data;
  } catch (e) {
    console.error(e);
  }
};

const getProviderInfo = async ({ userID }) => {
  try {
    const { data } = (await API.graphql(
      graphqlOperation(QUERIES.providersByUser, {
        // TODO update query
        userID
      })
    )) as GraphQLResult<ProvidersByUserQuery>;

    return data.providersByUser.items;
  } catch (e) {
    console.error(e);
  }
};

const setProviderInfo = async ({ provider, businessID }) => {
  try {
    const { data } = (await API.graphql(
      graphqlOperation(MUTATIONS.updateProvider, {
        input: {
          id: provider.id,
          _version: provider._version,
          businessID
        }
      })
    )) as GraphQLResult<UpdateProviderInput>;
    return data;
  } catch (e) {
    console.error(e);
  }
};

const getStaffInfo = async ({ userID }) => {
  try {
    const { data } = (await API.graphql(
      graphqlOperation(QUERIES.staffByUser, {
        userID
      })
    )) as GraphQLResult<StaffByUserQuery>;

    return data.staffByUser.items;
  } catch (e) {
    console.error(e);
  }
};

const getVendorInfo = async ({ userID }) => {
  try {
    const { data } = (await API.graphql(
      graphqlOperation(QUERIES.vendorsByUser, {
        userID
      })
    )) as GraphQLResult<VendorsByUserQuery>;

    return data.vendorsByUser.items;
  } catch (e) {
    console.error(e);
  }
};

const getDirectorInfo = async ({ userID }) => {
  try {
    const { data } = (await API.graphql(
      graphqlOperation(QUERIES.directorsByUser, {
        userID
      })
    )) as GraphQLResult<CreateVendorInput>;

    return data;
  } catch (e) {
    console.error(e);
  }
};

export {
  createUser,
  addToGroup,
  updateDeliverer,
  addDirector,
  addProvider,
  addVendor,
  addStaff,
  addDeliverer,
  getAllVendors,
  getAllDeliverers,
  getAllDirectors,
  getAllStaff,
  getAllProviders,
  getDeliverersByUniversity,
  getVendorsByUniversity,
  getStaffByUniversity,
  getDirectorInfo,
  getVendorInfo,
  getStaffInfo,
  getProviderInfo,
  setProviderInfo,
  acceptVendor,
  acceptStaff,
  acceptDeliverer
};
