import React, { useState, useEffect } from 'react';
import {
  Form,
  Input,
  Select,
  Button,
  Checkbox,
  InputNumber,
  Upload,
  message
} from 'antd';
import {
  LoadingOutlined,
  PlusOutlined,
  InfoCircleOutlined
} from '@ant-design/icons';
import { Tooltip } from 'antd';
import { getBusinessCategories } from '@campus/shared/services/BusinessService';

export function UpdateBusinessForm({
  onCancel,
  onSubmit,
  isLoading,
  business
}) {
  const CheckboxGroup = Checkbox.Group;
  const [form] = Form.useForm();
  const { Option } = Select;
  const [addressVisible, setAddressVisible] = useState(false);
  const [categoryList, setCategoryList] = useState(null);

  useEffect(() => {
    getCategories();
    business && setAddressVisible(business.locationOptionOnSite);
  }, []);

  const getCategories = async () => {
    const categories = await getBusinessCategories();
    setCategoryList(categories.listCategorys.items);
  };

  const onFinish = async values => {
    const businessData = {
      id: business.id,
      _version: business._version,
      locationOptionOnSite: values.location.includes('business'),
      locationOptionVisit: values.location.includes('house'),
      name: values.name,
      shortLocation: values.address || null,
      description: values.description || null,
      links: values.link ? [values.link] : [],
      categories: values.categories ? values.categories : null
    };
    onSubmit(businessData);
  };

  const locationOptions = [
    { label: 'At Business', value: 'business' },
    { label: 'House Visit', value: 'house' }
  ];

  const renderCategories = categoryList
    ? Object.values(categoryList)
        .map(category => {
          return (
            <Option
              key={category.id}
              value={category.id}
              category={category.title}
            >
              {category.title}
            </Option>
          );
        })
        .sort((a, b) => a.props.category.localeCompare(b.props.category))
    : null;

  if (!categoryList) return null;

  return (
    <div className="flex flex-col pt-8">
      <div className="w-full text-center">
        <h2 className="text-lg">Update Business</h2>
      </div>
      <div className="mt-4">
        {!!business && (
          <Form
            form={form}
            labelCol={{ span: 6 }}
            wrapperCol={{ span: 16 }}
            layout="horizontal"
            onFinish={onFinish}
            onValuesChange={(changed, all) => {
              if (changed.location)
                setAddressVisible(changed.location.includes('business'));
            }}
            initialValues={{
              business: business,
              name: business.name,
              address: business.shortLocation,
              description: business.description,
              link: business.links ? business.links[0] : '',
              location: [
                business.locationOptionOnSite && 'business',
                business.locationOptionVisit && 'house'
              ],
              categories: business.categories ? business.categories : []
            }}
          >
            <div className="px-5 pb-5 h-auto overflow-auto">
              <Form.Item
                label="Name"
                name="name"
                rules={[
                  { required: true, message: 'Give your business a name.' }
                ]}
              >
                <Input placeholder="Jackie's Pasta..." />
              </Form.Item>

              <Form.Item
                label="Business Description"
                name="description"
                rules={[{ message: 'Describe your business to customers.' }]}
              >
                <Input placeholder="+1 123-123-1234" />
              </Form.Item>
              <Form.Item
                label="Categories"
                name="categories"
                rules={[
                  {
                    required: true,
                    message:
                      'Help people find your business easier by adding a category.'
                  }
                ]}
              >
                <Select
                  mode="multiple"
                  allowClear
                  placeholder="Choose categories for your business"
                >
                  {renderCategories}
                </Select>
              </Form.Item>
              <Form.Item
                label="Place of Business"
                name="location"
                rules={[{ required: true, message: 'Select at least one.' }]}
              >
                <CheckboxGroup options={locationOptions} />
              </Form.Item>

              {addressVisible && (
                <Form.Item
                  label="Business Address"
                  name="address"
                  rules={[{ required: true, message: 'Help people find you.' }]}
                >
                  <Input placeholder="123 Street Ave, City ST..." />
                </Form.Item>
              )}

              <Form.Item
                label="Link 1 (Optional)"
                name="link"
                rules={[{ required: false }]}
              >
                <Input placeholder="Ex. www.instagram.com/jaki_garden" />
              </Form.Item>
            </div>

            <div className="flex items-center">
              <Button
                className="flex-1"
                onClick={() => {
                  onCancel();
                }}
                style={{
                  borderRadius: 0,
                  height: 50,
                  backgroundColor: '#efefef',
                  border: 0,
                  borderBottomLeftRadius: 2,
                  borderBottomRightRadius: 0
                }}
              >
                CANCEL
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                className="flex-1"
                loading={isLoading}
                style={{
                  borderRadius: 0,
                  height: 50,
                  border: 0,
                  borderBottomLeftRadius: 0,
                  borderBottomRightRadius: 2
                }}
              >
                UPDATE
              </Button>
            </div>
          </Form>
        )}
      </div>
    </div>
  );
}
