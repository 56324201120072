import React, { useEffect, useState } from 'react';
import { Button } from 'antd';
import { Auth } from 'aws-amplify';
import { navigate } from 'gatsby-link';
import { SplitLayout } from '../../Layout';
import { LoginBack } from '../../Routes/PublicRoute';
import { getStripeClientID } from '@campus/shared/services/PaymentService';

const StripeFlowStart = () => {
  const clientID = getStripeClientID();

  const urlPrefix = window.location.host.includes('localhost')
    ? 'http://'
    : 'https://';
  const stripeUri = `https://connect.stripe.com/express/oauth/authorize?response_type=code&client_id=${clientID}&scope=read_write&redirect_uri`;
  const redirectUri = `${stripeUri}=${urlPrefix +
    window.location.host}/app/stripe-complete/`;

  const connectStripe = async () => {
    window.location.href = redirectUri;
  };

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    checkUser();
  }, []);

  const checkUser = async () => {
    const user = await Auth.currentAuthenticatedUser();
    const userGroups =
      user.signInUserSession.accessToken.payload['cognito:groups'];

    if (!userGroups.includes('providers')) {
      console.log(
        'User does not have permission to set up Stripe, navigating to safety.'
      );
      navigate('/');
      return;
    }

    setLoading(false);
  };

  return (
    <SplitLayout rightComponent={<LoginBack />}>
      <div className="h-full flex flex-col items-center justify-center">
        <div className="w-1/2 flex flex-col space-y-6">
          {loading ? (
            <div className="h-full flex flex-col">
              <div className="text-xl py-4">Verifying account...</div>
            </div>
          ) : (
            <div className="h-full flex flex-col">
              <div className="text-xl py-4">
                Please connect a Stripe account.
              </div>

              <Button
                className="w-full"
                onClick={() => connectStripe()}
                type="primary"
              >
                Set Up with Stripe
              </Button>
            </div>
          )}
        </div>
      </div>
    </SplitLayout>
  );
};

export default StripeFlowStart;
