import { API, graphqlOperation, Storage } from 'aws-amplify';
import { AmpAPI, configureAmplify } from './AmplifyService';
import { MUTATIONS, QUERIES, AWS_CONFIG, SUBS } from '@campus/backend';
import { GraphQLResult } from '@aws-amplify/api-graphql';
import {
  BookingsByBusinessQuery,
  BookingsByCustomerQuery,
  BusinessesByUniversityQuery,
  CreateBusinessInput,
  CreateServiceInput,
  CreateVenueInput,
  DeleteServiceInput,
  GetBusinessQuery,
  GetVenueQuery,
  ServicesByBusinessQuery,
  UpdateBusinessInput,
  UpdateServiceMutation,
  ListCategorysQuery
} from '@campus/backend/API';
import { getProvider } from './UserService';

const {
  aws_user_files_s3_bucket_region: region,
  aws_user_files_s3_bucket: bucket
} = AWS_CONFIG;
configureAmplify();

const createBusiness = async params => {
  try {
    const { data } = (await API.graphql(
      graphqlOperation(MUTATIONS.createBusiness, {
        input: params
      })
    )) as GraphQLResult<CreateBusinessInput>;
    console.log('raw data:', data);
    return data['createBusiness'];
  } catch (e) {
    console.error(e);
  }
};

const updateBusiness = async params => {
  try {
    console.log('Update Business w/', params);
    const { data } = (await API.graphql(
      graphqlOperation(MUTATIONS.updateBusiness, {
        input: params
      })
    )) as GraphQLResult<UpdateBusinessInput>;

    return data['updateBusiness'];
  } catch (e) {
    console.error(e);
  }
};
const getBusiness = async params => {
  try {
    console.log('Get Business w/', params);
    const { data } = (await API.graphql(
      graphqlOperation(QUERIES.getBusiness, {
        id: params
      })
    )) as GraphQLResult<GetBusinessQuery>;

    return data['getBusiness'];
  } catch (e) {
    console.error(e);
  }
};

const getBusinessImage = async business => {
  try {
    const { image, image_url } = business;
    if (image) {
      return await Storage.get(image.key);
    } else if (image_url) {
      return image_url;
    } else {
      return null; //Placeholder image if there is nothing saved yet
    }
  } catch (e) {
    console.log(e);
  }
};

const uploadBusinessImage = async (business, file) => {
  const { id, _version } = business;
  const { type } = file;

  try {
    // get the file extension. eg. jpeg
    const fileExt = type.split('/').pop();
    // create a unique name for the uploaded file + extension
    const key = `business/${id}-business-picture.${fileExt}`;
    // prepared gql mutation parameter
    const fileForUpload = {
      bucket,
      key,
      region
    };

    // uploads the image
    await Storage.put(key, file, {
      contentType: type
    });
    console.log('uploaded image, ', key);
    // Prepare data to be updated
    const inputData = { id, _version, image: fileForUpload };
    // Update Provider
    const data = await updateBusiness(inputData);

    return data;
  } catch (e) {
    console.error(e);
  }
};

const getBusinessesByUniversity = async input => {
  try {
    const { data } = (await API.graphql(
      graphqlOperation(QUERIES.businessesByUniversity, { universityID: input })
    )) as GraphQLResult<BusinessesByUniversityQuery>;
    return data.businessesByUniversity.items;
  } catch (e) {
    console.error(e);
  }
};

const getBookingByBusiness = async input => {
  try {
    const { data } = (await API.graphql(
      graphqlOperation(QUERIES.bookingsByBusiness, {
        businessID: input
      })
    )) as GraphQLResult<BookingsByBusinessQuery>;
    return data.bookingsByBusiness.items;
  } catch (e) {
    console.error(e);
  }
};

const getBookingByBusinessAndDate = async (input, startDate, endDate) => {
  try {
    const { data } = (await API.graphql(
      graphqlOperation(QUERIES.bookingsByBusiness, {
        businessID: input,
        filter: {
          status: {
            ne: 'PAYMENT_ERROR'
          },
          date: {
            ge: startDate,
            lt: endDate
          }
        }
      })
    )) as GraphQLResult<BookingsByBusinessQuery>;
    return data.bookingsByBusiness.items;
  } catch (e) {
    console.error(e);
  }
};

const onUpdateBookingByBusinessID$ = businessID => {
  return API.graphql(
    graphqlOperation(SUBS.onUpdateBookingByBusiness, {
      businessID: businessID
    })
  );
};

const onCreateBookingByBusinessID$ = businessID => {
  return API.graphql(
    graphqlOperation(SUBS.onCreateBookingByBusiness, {
      businessID: businessID
    })
  );
};

const getBookingByCustomer = async input => {
  try {
    const { data } = (await API.graphql(
      graphqlOperation(QUERIES.bookingsByCustomer, { customerID: input })
    )) as GraphQLResult<BookingsByCustomerQuery>;
    return data.bookingsByCustomer.items;
  } catch (e) {
    console.error(e);
  }
};

const getServices = async businessID => {
  try {
    console.log('getServices: ', businessID);
    const { data } = (await API.graphql(
      graphqlOperation(QUERIES.servicesByBusiness, { businessID })
    )) as GraphQLResult<ServicesByBusinessQuery>;
    return data.servicesByBusiness.items;
  } catch (e) {
    console.error(e);
  }
};

const createService = async params => {
  try {
    const { data } = (await API.graphql(
      graphqlOperation(MUTATIONS.createService, {
        input: params
      })
    )) as GraphQLResult<CreateServiceInput>;
    return data;
  } catch (e) {
    console.error(e);
  }
};

const updateService = async params => {
  try {
    const { data } = (await API.graphql(
      graphqlOperation(MUTATIONS.updateService, {
        input: params
      })
    )) as GraphQLResult<UpdateServiceMutation>;
    return data;
  } catch (e) {
    console.error(e);
  }
};

const deleteService = async service => {
  try {
    const { data } = (await API.graphql(
      graphqlOperation(MUTATIONS.deleteService, {
        input: {
          id: service.id,
          _version: service._version
        }
      })
    )) as GraphQLResult<DeleteServiceInput>;
    return data;
  } catch (e) {
    console.error(e);
  }
};

const getBusinessCategories = async () => {
  try {
    const { data } = (await API.graphql(
      graphqlOperation(QUERIES.listCategorys)
    )) as GraphQLResult<ListCategorysQuery>;
    return data;
  } catch (e) {
    console.error(e);
  }
};

export {
  createBusiness,
  updateBusiness,
  getBusiness,
  getBusinessImage,
  uploadBusinessImage,
  getBusinessesByUniversity,
  getBookingByBusiness,
  getBookingByBusinessAndDate,
  getBookingByCustomer,
  onUpdateBookingByBusinessID$,
  onCreateBookingByBusinessID$,
  getServices,
  createService,
  updateService,
  deleteService,
  getBusinessCategories
};
