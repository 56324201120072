import React, { useState, useEffect } from 'react';
import { Col, Row, Menu, Button } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import Bookings from '../Business/Bookings';
import { PrivateLayout } from '../../Layout/AppLayouts';
import ProfileCard from '../Provider/ProfileCard';
import BusinessCard from '../Provider/BusinessCard';
import ServiceCard from '../Provider/ServiceCard';
import AvailabilityCard from '../Provider/AvailabilityCard';
import { navigate } from 'gatsby';
import { getCurrentUser } from '../../Auth/AppUser';

function ProviderDash({ businessID }) {
  const [tabSelected, setTabSelected] = useState('overview');
  const [warning, setWarning] = useState('');

  useEffect(() => {
    console.log(getCurrentUser());
    if (
      !getCurrentUser().userGroups.includes('admins') &&
      !getCurrentUser()['custom:stripe_connected_id']
    ) {
      setWarning(
        'Warning! You did not complete stripe set up. Fund transfer will not work and availability disabled.'
      );
    }
  }, []);

  return (
    <PrivateLayout>
      <div className="h-full">
        {!!warning && (
          <div className="border rounded my-2 mx-4 p-4 flex justify-between items-center">
            <div className="flex items-center">
              <InfoCircleOutlined
                className="red-600 "
                style={{ color: 'red' }}
              />
              <p className="text-red-600 px-3 p-0 m-0">{warning}</p>
            </div>
            <Button
              type="primary"
              onClick={() => navigate('/app/stripe-start')}
            >
              Complete Stripe Set Up
            </Button>
          </div>
        )}
        <Menu
          onClick={e => setTabSelected(e.key)}
          selectedKeys={[tabSelected]}
          mode="horizontal"
        >
          <Menu.Item key="overview">My Business</Menu.Item>
          <Menu.Item key="bookings">My Bookings</Menu.Item>
        </Menu>
        <div className="pb-32">
          {tabSelected === 'overview' ? (
            <div className="overflow-y-auto p-4">
              <Row gutter={[16, 16]} className="mt-0">
                <Col span={24} md={12}>
                  <ProfileCard />
                </Col>
                <Col span={24} md={12}>
                  <BusinessCard businessID={businessID} />
                </Col>
              </Row>
              <Row gutter={[16, 16]} className="mt-4">
                <Col span={24} lg={12}>
                  <ServiceCard />
                </Col>
                {!warning && (
                  <Col span={24} lg={12}>
                    <AvailabilityCard />
                  </Col>
                )}
              </Row>
            </div>
          ) : tabSelected === 'bookings' ? (
            <Bookings />
          ) : null}
        </div>
      </div>
    </PrivateLayout>
  );
}

export default ProviderDash;
