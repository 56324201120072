import React from 'react';
import { Row, Col, Card } from 'antd';

const HelpScreen = () => {
  return (
    <div className="p-5 h-full overflow-y-auto">
      <Row gutter={[32, 32]} className="p-4">
        <Col span={12}>
          <Card title="Venue">
            <div className="h-32 bg-gray-200 rounded-md flex justify-center items-center">
              <p className=" text-gray-600">Demo</p>
            </div>
          </Card>
        </Col>
        <Col span={12}>
          <Card title="Menu">
            <div className="h-32 bg-gray-200 rounded-md flex justify-center items-center">
              <p className=" text-gray-600">Demo</p>
            </div>
          </Card>
        </Col>
        <Col span={12}>
          <Card title="Event">
            <div className="h-32 bg-gray-200 rounded-md flex justify-center items-center">
              <p className=" text-gray-600">Demo</p>
            </div>
          </Card>
        </Col>
        <Col span={12}>
          <Card title="Deliverer">
            <div className="h-32 bg-gray-200 rounded-md flex justify-center items-center">
              <p className=" text-gray-600">Demo</p>
            </div>
          </Card>
        </Col>
        <Col span={12}>
          <Card title="Vendor">
            <div className="h-32 bg-gray-200 rounded-md flex justify-center items-center">
              <p className=" text-gray-600">Demo</p>
            </div>
          </Card>
        </Col>
        <Col span={12}>
          <Card title="Food">
            <div className="h-32 bg-gray-200 rounded-md flex justify-center items-center">
              <p className=" text-gray-600">Demo</p>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default HelpScreen;
