import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Space, Checkbox } from 'antd';

export function UpdateProviderForm({
  onCancel,
  onSubmit,
  isLoading,
  provider
}) {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      email: provider.invitationalEmail,
      firstName: provider.firstName,
      lastName: provider.lastName,
      phoneNumber: provider.phoneNumber,
      university: provider.university.name
    });
  }, [provider]);

  return (
    <div className="flex flex-col pt-8">
      <div className="w-full text-center">
        <h2 className="text-lg">Update Provider</h2>
      </div>
      <div className="flex-1 mt-4 px-5">
        <Form
          form={form}
          title="Update Provider"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 16 }}
          layout="horizontal"
          name="complex-form"
          onFinish={onSubmit}
        >
          <Form.Item
            label="First Name"
            name="firstName"
            style={{ marginBottom: 12 }}
          >
            <Input placeholder="Type here..." />
          </Form.Item>
          <Form.Item
            label="Last Name"
            name="lastName"
            style={{ marginBottom: 12 }}
          >
            <Input placeholder="Type here..." />
          </Form.Item>
          <Form.Item
            label="Phone Number"
            name="phoneNumber"
            style={{ marginBottom: 12 }}
          >
            <Input
              maxLength={10}
              addonBefore={<p className="p-0 m-0">+1</p>}
              placeholder="123-123-1234"
            />
          </Form.Item>
          <Form.Item label=" " colon={false}>
            <div className="flex justify-end pt-5">
              <Space className="self-end">
                <Button
                  type="secondary"
                  onClick={e => {
                    onCancel();
                  }}
                >
                  Cancel
                </Button>
                <Button
                  loading={isLoading}
                  type="primary"
                  htmlType="submit"
                  className="w-24"
                >
                  Save
                </Button>
              </Space>
            </div>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}
