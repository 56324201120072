import React, { useState, useEffect } from 'react';
import { Layout, Menu, message, PageHeader, Button } from 'antd';
import { navigate } from '@reach/router';
import { EditAccount } from './EditAccount';
import { Auth } from 'aws-amplify';
import { AppUser } from '../../Auth';
import UpdatePassword from './UpdatePassword';
import SupportRequest from './SupportRequest';
import HelpScreen from './HelpScreen';
import Terms from './Terms';
import Policy from './Policy';
import { useAppStore } from '../../../stores/app';
import { useUniversityStore } from '../../../stores/university';
import { useVenueStore } from '../../../stores/venue';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useBusinessStore } from '../../../stores/business';
import { SettingsLayout } from '../../Layout/AppLayouts';
const { Sider, Content } = Layout;

const SettingsNav = () => {
  const { logoutUser } = AppUser;
  const [appState, appActions] = useAppStore();
  const [venueState, venueActions] = useVenueStore();
  const [universityState, universityActions] = useUniversityStore();
  const [businessState, businessActions] = useBusinessStore();
  const [selected, setSelected] = useState('edit');
  const [isMobile, setIsMobile] = useState(false);
  const [stripeError, setStripeError] = useState(false);

  useEffect(() => {
    if (appState.group?.isDirector || appState.group?.isProvider) {
      checkStripe();
    }
  }, [appState]);

  const checkStripe = async () => {
    const user = await Auth.currentAuthenticatedUser();
    if (!user.attributes['custom:stripe_connected_id']) {
      setStripeError(true);
    }
  };

  const onSelect = ({ item, key, keyPath, domEvent }) => {
    if (key === 'logout') {
      logOut();
      return;
    } else if (key === 'stripe') {
      const hide = message.loading('Redirecting to Stripe...', 0);
      // Dismiss manually and asynchronously
      setTimeout(() => {
        hide();
        window.open('https://dashboard.stripe.com/login');
      }, 2500);
    }
    setSelected(key);
  };

  function logOut() {
    Auth.signOut()
      .then(() => {
        venueActions.setVenue(null);
        universityActions.setUniversity(null);
        appActions.setUserGroup([]);
        businessActions.setBusiness(null);
      })
      .then(logoutUser(() => navigate('/app/signin')))
      .catch(err => console.log('error: ', err));
  }

  return (
    <SettingsLayout>
      {stripeError && (
        <div className="bg-red-200 p-3 flex justify-between items-center">
          <div className="p-3 flex items-center">
            <InfoCircleOutlined className="red-600 " style={{ color: 'red' }} />
            <p className="text-red-600 px-3 p-0 m-0">
              Warning! You did not complete stripe set up. Fund transfer will
              not work properly.
            </p>
          </div>
          <Button type="primary" onClick={() => navigate('/app/stripe-start')}>
            Complete Stripe Set Up
          </Button>
        </div>
      )}
      <Layout className="h-full" hasSider={true}>
        <Sider
          theme="light"
          breakpoint="lg"
          collapsedWidth={0}
          zeroWidthTriggerStyle={{ top: 0 }}
          onBreakpoint={broken => {
            setIsMobile(broken);
            console.log(broken);
          }}
          onCollapse={(collapsed, type) => {
            console.log(collapsed, type);
          }}
        >
          <Menu
            onClick={onSelect}
            mode={'vertical'}
            theme="light"
            className="h-full"
          >
            <Menu.Item key="edit">Edit Account</Menu.Item>
            <Menu.Item key="password">Change Password</Menu.Item>
            <Menu.Item key="stripe">Stripe Dashboard</Menu.Item>
            <Menu.Item key="support">Support</Menu.Item>
            <Menu.Item key="help">Help</Menu.Item>
            <Menu.Item key="terms">Terms & Conditions</Menu.Item>
            <Menu.Item key="policy">Privacy Policy</Menu.Item>
            <Menu.Divider />
            <Menu.Item key="logout" danger>
              Log Out
            </Menu.Item>
          </Menu>
        </Sider>
        <Content>
          <div className="h-full bg-white relative">
            {selected === 'edit' ? (
              <EditAccount />
            ) : selected === 'password' ? (
              <div>
                <UpdatePassword />
              </div>
            ) : selected === 'support' ? (
              <SupportRequest />
            ) : selected === 'help' ? (
              <HelpScreen />
            ) : selected === 'terms' ? (
              <div className="h-full overflow-y-scroll">
                <Terms />
              </div>
            ) : selected === 'policy' ? (
              <div className="h-full overflow-y-scroll">
                <Policy />
              </div>
            ) : (
              <div></div>
            )}
          </div>
        </Content>
      </Layout>
    </SettingsLayout>
  );
};

export default SettingsNav;
